import * as React from "react";
const SvgDeviations = ({
  title,
  titleId,
  ...props
}) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 6.3499999 6.35", xmlns: "http://www.w3.org/2000/svg", stroke: "currentColor", "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 0.529167,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeDasharray: "none",
  strokeOpacity: 1
}, d: "m 3.1750002,5.6797238 -10e-8,-2.9015989 M 5.8208333,1.7197916 3.1750001,2.7781249 1.8520835,2.2489582 M 4.4979167,1.190625 5.8208333,1.7197916 5.8208332,4.630208 3.1750001,5.6885414 1.8520835,5.1593747" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 0.529167,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeDasharray: "0, 1.05833",
  strokeDashoffset: 0.3175,
  strokeOpacity: 1
}, d: "M 4.4979165,1.190625 3.1750002,0.66896754" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 0.529167,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeDasharray: "0,0.95250058",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, d: "M 3.1750002,0.66145837 0.52916685,1.7197916" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 0.529167,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeDasharray: "0, 1.05833",
  strokeDashoffset: 0.370417,
  strokeOpacity: 1
}, d: "M 1.8520834,2.2489582 0.52916685,1.7197916" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 0.529167,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeDasharray: "0, 1.05833",
  strokeDashoffset: 0.370417,
  strokeOpacity: 1
}, d: "M 1.8520834,5.1593749 0.52916687,4.6302083" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "none",
  strokeWidth: 0.529167,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeDasharray: "0, 0.9525",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, d: "M 0.52916685,4.630208 V 1.7197916" }));
export default SvgDeviations;
