import { getParentPath } from "utils/objectData";

import { ListNode, Node, Tree } from "./types";

/**
 * Expand all nodes leading to the given node if they are not already expanded.
 * @param autoexpand automatically expand single child nodes
 * @returns `tree`
 */
export function ensureExpandedTo<T>(tree: Tree<T>, path: string, autoexpand = true) {
    let newTree = tree;
    for (const parentPath of getParentPaths(path)) {
        const node = tree[parentPath];
        if (!node.expanded) {
            if (newTree === tree) {
                newTree = { ...tree };
            }
            newTree[parentPath] = { ...node, expanded: true };
        }
    }

    const node = newTree[path];

    if (autoexpand) {
        newTree = autoexpandSingleChildNodes(newTree, node, newTree !== tree);
    }

    return newTree;
}

/**
 * Autoexpand single child nodes starting from the given node.
 * Works only on loaded nodes.
 * @param tree node tree
 * @param from starting node
 * @param inPlace whether to modify the tree in place
 */
export function autoexpandSingleChildNodes<T>(tree: Tree<T>, from: Node<T>, inPlace: boolean) {
    let newTree = tree;
    let next = from;
    while (next.children?.length === 1) {
        if (!next.expanded) {
            if (newTree === tree && !inPlace) {
                newTree = { ...tree };
            }
            newTree[next.path] = { ...next, expanded: true };
        }
        next = newTree[next.children[0]];
    }

    return newTree;
}

/**
 * Expands group based on `groupKey`.
 * @returns `tree`
 */
export function expandGroup<T>(tree: Tree<T>, path: string, max?: number) {
    const node = tree[path];
    if (!node || !node.groupCollapsed) {
        return tree;
    }

    const parentPath = getParentPath(node.path);
    const parent = tree[parentPath];
    if (!parent || !parent.children) {
        return tree;
    }

    const nodeIndex = parent.children.indexOf(path);
    if (nodeIndex === -1) {
        return tree;
    }

    const newTree = { ...tree };

    const endIndex = max ? Math.min(parent.children.length, nodeIndex + max) : parent.children.length;
    for (let i = nodeIndex; i < endIndex; i++) {
        const childPath = parent.children[i];
        const child = newTree[childPath];
        if (child.groupKey !== node.groupKey || !child.groupCollapsed) {
            break;
        }

        newTree[childPath] = { ...child, groupCollapsed: false };
    }

    return newTree;
}

/**
 * Get all parent paths.
 * NOTE that's not a split on slash, but a set of prefixes.
 * E.g. for 'a/b/c' it will return '', 'a', 'a/b'.
 * @param path path to get parents for
 * @param includeRoot include root path ('') or not
 */
export function* getParentPaths(path: string, includeRoot = true) {
    let pos = 0;
    if (path !== "" && includeRoot) {
        yield "";
    }
    while (pos !== -1) {
        const nextPos = path.indexOf("/", pos);
        if (nextPos === -1) {
            break;
        }
        const parentPath = path.slice(0, nextPos);
        yield parentPath;
        pos = nextPos + 1;
    }
}

export function defaultBreadcrumbNodeLabel<T>(node: Node<T>) {
    if (node.name.length < 31) {
        return node.name;
    }
    return node.name.slice(0, 7) + "..." + node.name.slice(-7);
}

export function collapseAllNodes<T>(tree: Tree<T>) {
    const newTree = { ...tree };
    for (const path in newTree) {
        if (path !== "" && newTree[path].expanded) {
            newTree[path] = { ...newTree[path], expanded: false };
        }
    }
    return newTree;
}

/**
 * Convert tree into a flat list of indented items.
 * In addition to flattening it
 * - Collapses single child nodes
 * - Collapsed non-expanded nodes with the same `groupKey`
 */
export function makeNodeList<T>({
    tree,
    splitNodeCollapseAt,
}: {
    tree: Tree<T>;
    splitNodeCollapseAt?: (node: Node<T>) => boolean;
}) {
    const result: ListNode<T>[] = [];

    handleNode(tree[""], -1, null);

    return result;

    /**
     * @param listNode is used to collapse single child nodes
     */
    function handleNode(node: Node<T>, level: number, listNode: ListNode<T> | null) {
        if (!node.children || !node.children.length || !node.expanded) {
            return;
        }

        const nextLevel = level + 1;
        let groupListNode: ListNode<T> | undefined;
        let nodeGroup: Node<T>[] = [];
        let prevChild: Node<T> | undefined;

        if (
            node.children.length === 1 &&
            listNode &&
            (!splitNodeCollapseAt || !splitNodeCollapseAt(node)) &&
            hasExpandedNonSingleChildNode(tree, node)
        ) {
            const child = tree[node.children[0]];
            listNode.nodes = [...(listNode.nodes ?? []), child];
            handleNode(child, level, listNode);
            return;
        }

        const maybeFinalizeGroupNode = () => {
            if (groupListNode && prevChild && groupListNode.node !== prevChild) {
                groupListNode.nodeGroup = nodeGroup;
            }
            groupListNode = undefined;
        };

        let lastListNode: ListNode<T> | undefined;

        for (const childPath of node.children) {
            const child = tree[childPath];

            if (groupListNode && child.groupKey === groupListNode.node.groupKey && child.groupCollapsed) {
                prevChild = child;
                nodeGroup.push(child);
                continue;
            }

            const listNode: ListNode<T> = {
                node: child,
                parent: node,
                level: nextLevel,
            };
            lastListNode = listNode;
            result.push(listNode);
            handleNode(child, nextLevel, listNode);

            maybeFinalizeGroupNode();

            if (child.groupKey && child.groupCollapsed) {
                groupListNode = listNode;
                nodeGroup = [child];
            }

            prevChild = child;
        }
        maybeFinalizeGroupNode();

        if (node.hasMoreChildren) {
            result.push({
                node,
                parent: listNode?.node ?? null,
                level: nextLevel,
                isLoadMore: true,
            });
        }

        if (lastListNode) {
            lastListNode.isLast = true;
        }
    }
}

function hasExpandedNonSingleChildNode<T>(tree: Tree<T>, node: Node<T>) {
    if (node.expanded && node.children) {
        if (node.children.length > 1) {
            return true;
        }
        if (node.children.length === 1) {
            return hasExpandedNonSingleChildNode(tree, tree[node.children[0]]);
        }
    }
    return false;
}
