import { ObjectId } from "@novorender/api";
import { SearchPattern } from "@novorender/data-js-api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { Confirmation, TextField } from "components";
import { Node } from "components/objectTree/types";
import SimpleSnackbar from "components/simpleSnackbar";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { GroupStatus, ObjectGroup, objectGroupsActions, useDispatchObjectGroups } from "contexts/objectGroups";
import { useAbortController } from "hooks/useAbortController";
import { AsyncState, AsyncStatus } from "types/misc";
import { getObjectNameFromPath, getParentPath } from "utils/objectData";
import { searchDeepByPatterns } from "utils/search";

import { ModelTreeNodeData } from "../types";

export function SaveToGroups({
    onCreate,
    headerShadow = true,
}: {
    onCreate: (groupId: string) => void;
    headerShadow?: boolean;
}) {
    const {
        state: { db },
    } = useExplorerGlobals(true);
    const { t } = useTranslation();
    const node = (useLocation().state as { node: Node<ModelTreeNodeData> }).node;
    const history = useHistory();
    const [saveStatus, setSaveStatus] = useState<AsyncState<void>>({ status: AsyncStatus.Initial });
    const [abortController] = useAbortController();
    const dispatchObjectGroups = useDispatchObjectGroups();

    const [grouping, setGrouping] = useState(() => {
        const parentPath = getParentPath(node.path);
        return parentPath ? getObjectNameFromPath(parentPath) : "";
    });
    const [groupName, setGroupName] = useState(node.groupKey && node.groupCollapsed ? node.groupKey : node.name);

    if (!node) {
        history.goBack();
    }

    const handleConfirm = async () => {
        setSaveStatus({ status: AsyncStatus.Loading });
        try {
            const ids = new Set<ObjectId>();
            const path = node.groupKey ? `${getParentPath(node.path)}/${node.groupKey}` : node.path;
            const searchPatterns: SearchPattern[] = [{ property: "path", value: [path] }];
            await searchDeepByPatterns({
                abortSignal: abortController.current.signal,
                db,
                searchPatterns,
                callback: (result) => {
                    for (const id of result) {
                        ids.add(id);
                    }
                },
            });
            const newGroup: ObjectGroup = {
                name: groupName,
                includeDescendants: true,
                ids,
                grouping,
                id: crypto.randomUUID(),
                status: GroupStatus.None,
                search: searchPatterns,
                color: [1, 0, 0, 1],
                opacity: 0,
            };

            dispatchObjectGroups(objectGroupsActions.add([newGroup]));
            onCreate(newGroup.id);

            history.goBack();
        } catch (ex) {
            if ((ex as { name: string }).name === "AbortError") {
                return;
            }
            console.warn(ex);
            setSaveStatus({ status: AsyncStatus.Error, msg: t("errorOccurred") });
        }
    };

    return (
        <Confirmation
            title={t("saveToGroups")}
            confirmBtnText={t("save")}
            onCancel={history.goBack}
            onConfirm={handleConfirm}
            loading={saveStatus.status === AsyncStatus.Loading}
            confirmBtnDisabled={!groupName}
            headerShadow={headerShadow}
        >
            <TextField
                value={grouping}
                onChange={(e) => setGrouping(e.target.value)}
                label={t("collection")}
                fullWidth
            />
            <TextField
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                label={t("name")}
                fullWidth
                autoFocus
                sx={{ my: 2 }}
            />
            <SimpleSnackbar
                hideDuration={3000}
                close={() => setSaveStatus({ status: AsyncStatus.Initial })}
                message={saveStatus.status === AsyncStatus.Error ? saveStatus.msg : ""}
            />
        </Confirmation>
    );
}
