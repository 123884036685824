import { useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { type CadCamera } from "features/render/types";
import { selectProjectV2Info } from "slices/explorer";

import { getDefaultCamera } from "../utils";

export function useDefaultCamera(): CadCamera | undefined {
    const project = useAppSelector(selectProjectV2Info);
    const aabb = useExplorerGlobals().state.scene?.aabb;

    const bb = aabb
        ? ([aabb.min[0], aabb.min[1], aabb.max[0], aabb.max[1]] as [number, number, number, number])
        : project?.bounds;
    if (!bb) {
        return;
    }

    return getDefaultCamera(bb);
}
