import { CameraAlt } from "@mui/icons-material";
import { css, styled } from "@mui/material";
import { RenderStateClippingPlane } from "@novorender/api";
import { ReadonlyVec3, vec3 } from "gl-matrix";
import { forwardRef, SVGProps, useCallback, useEffect, useImperativeHandle, useRef } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { selectViewMode } from "features/render";
import { useRedirectWheelEvents } from "hooks/useRedirectWheelEvents";
import { AsyncStatus, ViewMode } from "types/misc";

import { imagesActions, selectActiveImage, selectImages, selectShowImageMarkers } from "./imagesSlice";
import { Image, ImageType } from "./types";
import { isPanorama } from "./utils";

const Marker = styled(
    forwardRef<SVGGElement, SVGProps<SVGGElement>>((props, ref) => (
        <g {...props} ref={ref}>
            <CameraAlt color="primary" height="32px" width="32px" />
        </g>
    )),
)(
    () => css`
        cursor: pointer;
        pointer-events: bounding-box;

        svg {
            filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
        }
    `,
);

export const ImageMarkers = forwardRef<{ update: () => void }>(function ImageMarkers(_, ref) {
    const {
        state: { view },
    } = useExplorerGlobals();

    const dispatch = useAppDispatch();
    const images = useAppSelector(selectImages);
    const showImageMarkers = useAppSelector(selectShowImageMarkers);
    const viewMode = useAppSelector(selectViewMode);
    const activeImage = useAppSelector(selectActiveImage);

    const onWheel = useRedirectWheelEvents();
    const containerRef = useRef<(SVGGElement | null)[]>([]);

    const update = useCallback(() => {
        if (!view?.measure || !containerRef.current.length || images.status !== AsyncStatus.Success) {
            return;
        }

        // Ideally we would filter out invisible points and not create HTML elements for them at all,
        // but it makes managing them harder since we update HTML directly
        view.convert.worldSpaceToScreenSpace(images.data.map((marker) => marker.position)).forEach((pos, idx) => {
            const node = containerRef.current[idx];
            if (!node) {
                return;
            }

            const visible = !isPointClipped(view.renderState.clipping.planes, images.data[idx].position);

            node.setAttribute(
                "transform",
                pos && visible ? `translate(${pos[0] - 25} ${pos[1] - 25})` : "translate(-100 -100)",
            );
        });
    }, [images, view]);

    useEffect(() => {
        if (!view) {
            return;
        }

        let prevCamera = view.renderState.camera;
        let prevClippingPlanes = view.renderState.clipping.planes;
        let mounted = true;

        let raf = requestAnimationFrame(draw);

        function draw() {
            if (!view) {
                return;
            }

            const camera = view.renderState.camera;
            const clippingPlanes = view.renderState.clipping.planes;
            if (camera !== prevCamera || clippingPlanes !== prevClippingPlanes) {
                update();
                prevCamera = camera;
                prevClippingPlanes = clippingPlanes;
            }

            if (mounted) {
                raf = requestAnimationFrame(draw);
            }
        }

        return () => {
            mounted = false;
            if (raf !== undefined) {
                cancelAnimationFrame(raf);
            }
        };
    }, [view, update]);

    // Markers `update` is called whenever camera changes, but image markers have to respect clipping planes,
    // which is not tracked by markers containers.
    // So instead image markers render with it's own requestAnimationFrame and ignore `update` calls.
    // If we'll need other markers to respect clipping planes, we'll have to refactor this.
    useImperativeHandle(ref, () => ({ update: emptyFunction }), []);

    useEffect(() => {
        update();
    }, [update, showImageMarkers]);

    const openImage = (image: Image) => {
        if (isPanorama(image)) {
            dispatch(
                imagesActions.setActiveImage({
                    image: image,
                    mode: ImageType.Panorama,
                    status: AsyncStatus.Loading,
                }),
            );
        } else {
            dispatch(
                imagesActions.setActiveImage({
                    image: image,
                    mode: ImageType.Flat,
                    status: AsyncStatus.Loading,
                }),
            );
        }
    };

    return (
        <>
            {images.status === AsyncStatus.Success && showImageMarkers
                ? images.data.map((image, idx) => {
                      // image guid is not guaranteed to be unique
                      const key = idx;

                      if (!activeImage || viewMode !== ViewMode.Panorama) {
                          return (
                              <Marker
                                  key={key}
                                  onClick={() => openImage(image)}
                                  onWheel={onWheel}
                                  ref={(el) => (containerRef.current[idx] = el)}
                              />
                          );
                      }

                      const activeIdx = images.data.findIndex((image) => image.guid === activeImage.image.guid);

                      if (Math.abs(idx - activeIdx) === 1) {
                          return (
                              <Marker
                                  key={key}
                                  onClick={() => openImage(image)}
                                  onWheel={onWheel}
                                  ref={(el) => (containerRef.current[idx] = el)}
                              />
                          );
                      }

                      return null;
                  })
                : null}
        </>
    );
});

function isPointClipped(planes: readonly RenderStateClippingPlane[] | undefined, point: ReadonlyVec3) {
    if (!planes?.length) {
        return false;
    }

    const planeDir = vec3.create();
    const planeCenter = vec3.create();
    const dir = vec3.create();
    for (const { normalOffset } of planes) {
        vec3.set(planeDir, normalOffset[0], normalOffset[1], normalOffset[2]);
        vec3.scale(planeCenter, planeDir, normalOffset[3]);
        vec3.sub(dir, point, planeCenter);
        if (vec3.dot(dir, planeDir) > 0) {
            return true;
        }
    }

    return false;
}

function emptyFunction() {}
