import { ObjectDB } from "@novorender/data-js-api";
import { HierarcicalObjectReference } from "@novorender/data-js-api";
import pMap from "p-map";

import { AsyncTreeChangeResult, Node, Tree } from "components/objectTree/types";
import { autoexpandSingleChildNodes, ensureExpandedTo, getParentPaths } from "components/objectTree/utils";
import { NodeType } from "types/misc";
import { getFilePathFromObjectPath, getObjectNameFromPath } from "utils/objectData";
import { iterateAsync } from "utils/search";

import { ModelTreeFilter, ModelTreeFilterObjects, ModelTreeFilterType, ModelTreeNodeData } from "./types";

export function expandNode({
    tree,
    path,
    db,
    abortSignal,
    fileTypes,
}: {
    tree: Tree<ModelTreeNodeData>;
    path: string;
    db: ObjectDB;
    abortSignal: AbortSignal;
    fileTypes?: ModelTreeFilterObjects[];
}): AsyncTreeChangeResult<ModelTreeNodeData> {
    const node = tree[path];
    if (!node || node.expanded || node.isLeaf) {
        return { tree };
    }

    if (node.children) {
        // if already loaded - just expand
        const newTree: Tree<ModelTreeNodeData> = { ...tree, [path]: { ...node, expanded: true } };
        autoexpandSingleChildNodes(newTree, node, true);
        return { tree: newTree };
    }

    return {
        tree: { ...tree, [path]: { ...node, loading: true } },
        rollback: (tree) => ({ ...tree, [path]: { ...node, loading: false } }),
        load: async () => {
            const parts: ({ path: string } & Awaited<ReturnType<typeof loadChildren>>)[] = [];
            let currentPath = path;

            // Autoexpand max 5 nodes to avoid very deep/long waterfall
            for (let i = 0; i < 5; i++) {
                const { descendants, iterator } = await loadChildren({
                    db,
                    path: currentPath,
                    tree,
                    fileTypes: fileTypes,
                    abortSignal,
                });
                parts.push({ path: currentPath, descendants, iterator });
                if (descendants.length !== 1 || descendants[0].type === NodeType.Leaf) {
                    break;
                }
                currentPath = descendants[0].path;
            }

            return (tree, relevant) => {
                tree = { ...tree };

                for (const { path, descendants, iterator } of parts) {
                    const node = tree[path];
                    if (!node) {
                        break;
                    }

                    const newNode = appendDescendants({ tree, node, descendants, iterator });
                    newNode.loading = false;
                    if (relevant) {
                        newNode.expanded = true;
                    }

                    tree[path] = newNode;
                }

                return tree;
            };
        },
    };
}

function makeNode(obj: HierarcicalObjectReference): Node<ModelTreeNodeData> {
    const name = getObjectNameFromPath(obj.path);

    const node: Node<ModelTreeNodeData> = {
        expanded: false,
        isLeaf: obj.type === NodeType.Leaf,
        name,
        path: obj.path,
        data: { object: obj },
        loading: false,
    };

    if (node.isLeaf) {
        const m = name.match(/^(.+)\s+\(?\d+\)?$/);
        if (m) {
            node.groupKey = m[1];
            node.groupCollapsed = true;
        }
    }

    return node;
}

export function collapseNode({ tree, path }: { tree: Tree<ModelTreeNodeData>; path: string }) {
    const node = tree[path];
    if (!node) {
        return tree;
    }

    return { ...tree, [path]: { ...node, expanded: false } };
}

export function selectNode({
    tree,
    mainObject,
    db,
    abortSignal,
    fileTypes,
}: {
    tree: Tree<ModelTreeNodeData>;
    mainObject: HierarcicalObjectReference;
    db: ObjectDB;
    abortSignal: AbortSignal;
    fileTypes?: ModelTreeFilterObjects[];
}): AsyncTreeChangeResult<ModelTreeNodeData> {
    // if already in the tree - just expand all parents
    if (tree[mainObject.path]) {
        return { tree: ensureExpandedTo(tree, mainObject.path, false) };
    }

    // if filtered - check that this object can be visible at all
    if (fileTypes) {
        const satisfiesFilter = fileTypes.some(({ objects }) =>
            objects.some((obj) => mainObject.path.startsWith(obj.path) || obj.path.startsWith(mainObject.path)),
        );
        if (!satisfiesFilter) {
            return { tree: tree };
        }
    }

    const parentPaths = [...getParentPaths(mainObject.path)];
    const mainObjectParentPath = parentPaths.at(-1);
    const pathsWithMissingDescendants = tree
        ? parentPaths.filter((path) => !tree[path]?.children || path === mainObjectParentPath)
        : parentPaths;
    const newTree = { ...tree };

    for (const path of parentPaths) {
        const node = newTree[path];
        if (node && !node.expanded) {
            newTree[path] = node.children ? { ...node, expanded: true } : { ...node, loading: true };
        }
    }

    const load = async () => {
        const [[criticalPath], descendantLists] = await Promise.all([
            iterateAsync({
                iterator: db.search(
                    { descentDepth: -(parentPaths.length + 1), parentPath: mainObject.path },
                    abortSignal,
                ),
                count: 100,
                abortSignal,
            }),
            pMap(
                pathsWithMissingDescendants,
                async (path) => {
                    const { descendants, iterator } = await loadChildren({
                        db,
                        path,
                        tree,
                        fileTypes: fileTypes,
                        abortSignal,
                        ensureObjectFound: path === mainObjectParentPath ? mainObject : undefined,
                    });

                    return { path, descendants, iterator };
                },
                { concurrency: 4, signal: abortSignal },
            ),
        ]);

        return (tree: Tree<ModelTreeNodeData>, relevant: boolean) => {
            const newTree = { ...tree };
            [tree[""].data.object, ...criticalPath].forEach((obj) => {
                let node = newTree[obj.path];
                if (!node) {
                    node = {
                        expanded: true,
                        name: getObjectNameFromPath(obj.path),
                        path: obj.path,
                        isLeaf: obj.type === NodeType.Leaf,
                        data: { object: obj },
                        loading: false,
                    };
                } else {
                    node = { ...node };
                }
                if (relevant) {
                    node.expanded = true;
                }
                node.loading = false;
                const descendants = descendantLists.find((d) => d.path === obj.path);
                if (descendants) {
                    node = appendDescendants({
                        tree: newTree,
                        node,
                        descendants: descendants.descendants,
                        iterator: descendants.iterator,
                    });
                }
                newTree[obj.path] = node;
            });

            return newTree;
        };
    };

    return {
        tree: newTree,
        rollback: (tree) => {
            let newTree = tree;
            for (const path of parentPaths) {
                const node = newTree[path];
                if (node && node.loading) {
                    if (newTree === tree) {
                        newTree = { ...tree };
                    }
                    newTree[path] = { ...node, loading: false };
                }
            }
            return newTree;
        },
        load,
    };
}

export function filterOutObjectsNotOnPath(tree: Tree<ModelTreeNodeData>, paths: string[]) {
    const pathSet = new Set<string>();
    for (const concretePath of paths) {
        for (const path of getParentPaths(concretePath, false)) {
            pathSet.add(path);
        }
    }

    const newTree: Tree<ModelTreeNodeData> = {};
    Object.values(tree).forEach((node) => {
        if (pathSet.has(node.path)) {
            newTree[node.path] = node;
        }
    });

    Object.values(newTree).forEach((node) => {
        if (node.children) {
            node = { ...node, children: node.children.filter((child) => newTree[child]) };
            newTree[node.path] = node;
        }
    });

    return newTree;
}

export async function loadFilesWithType({
    db,
    abortSignal,
    fileTypes,
}: {
    db: ObjectDB;
    abortSignal: AbortSignal;
    fileTypes: ModelTreeFilter[];
}) {
    const hasTerrain = fileTypes.some((f) => f.type === ModelTreeFilterType.Terrain);
    const parserTypes = fileTypes.filter((f) => f.type !== ModelTreeFilterType.Terrain).map((f) => f.parserType);

    const [[terrainObjects], [genericTypeObjects]] = await Promise.all([
        hasTerrain
            ? iterateAsync({
                  iterator: db.search(
                      {
                          searchPattern: [
                              {
                                  property: "id",
                                  value: Array.from({ length: 100 }, (_, i) => i.toString()),
                                  exact: true,
                              },
                          ],
                          full: true,
                      },
                      abortSignal,
                  ),
                  count: 100,
                  abortSignal: abortSignal,
              })
            : [[]],
        parserTypes.length
            ? iterateAsync({
                  iterator: db.search(
                      { searchPattern: [{ property: "Parser/Type", value: parserTypes, exact: true }], full: true },
                      abortSignal,
                  ),
                  count: 3000,
                  abortSignal: abortSignal,
              })
            : [[]],
    ]);
    const objectsByFileType = new Map<string, HierarcicalObjectReference[]>();

    if (hasTerrain) {
        objectsByFileType.set(filterTypeToStr({ type: ModelTreeFilterType.Terrain }), terrainObjects);
    }

    for (const obj of genericTypeObjects) {
        const fileName = getFilePathFromObjectPath(obj.path);
        if (!fileName) {
            continue;
        }
        const parserType = (await obj.loadMetaData()).properties.find((p) => p[0] === "Parser/Type")?.[1];
        if (!parserType) {
            continue;
        }
        const key = filterTypeToStr({ type: ModelTreeFilterType.ParserType, parserType });
        let parserTypeObjects = objectsByFileType.get(key);
        if (!parserTypeObjects) {
            parserTypeObjects = [];
            objectsByFileType.set(key, parserTypeObjects);
        }
        parserTypeObjects.push(obj);
    }
    return objectsByFileType;
}

function* getDescendantsFilteredByParserTypeInfo(path: string, fileTypeFilter: ModelTreeFilterObjects[]) {
    for (const { objects } of fileTypeFilter) {
        for (const obj of objects) {
            if (obj.path.startsWith(path)) {
                const nextSlash = obj.path.indexOf("/", path.length + 1);
                yield nextSlash !== -1 ? obj.path.slice(0, nextSlash) : obj.path;
            }
        }
    }
}

export function collapseAllBelowFileLevel(tree: Tree<ModelTreeNodeData>) {
    const treeMap = { ...tree };
    for (const node of Object.values(treeMap)) {
        const filePath = getFilePathFromObjectPath(node.path);
        if (filePath && filePath.length <= node.path.length && node.expanded) {
            treeMap[node.path] = { ...node, expanded: false };
        }
    }
    return treeMap;
}

export async function getObjectParentFile({
    db,
    mainObject,
    fileTypes,
    tree,
    abortSignal,
}: {
    db: ObjectDB;
    mainObject: HierarcicalObjectReference;
    fileTypes?: ModelTreeFilterObjects[];
    tree?: Tree<ModelTreeNodeData>;
    abortSignal: AbortSignal;
}) {
    if (fileTypes) {
        for (const { objects } of fileTypes) {
            for (const obj of objects) {
                if (mainObject.path.startsWith(obj.path)) {
                    return obj;
                }
            }
        }
    }

    const filePath = getFilePathFromObjectPath(mainObject.path);
    if (!filePath) {
        return;
    }

    if (tree) {
        const node = tree[filePath];
        if (node) {
            return node.data.object;
        }
    }

    for await (const obj of db.search({ parentPath: filePath, descentDepth: 0, full: true }, abortSignal)) {
        return obj;
    }
}

export function loadMore({
    db,
    tree,
    path,
    fileTypes,
    abortSignal,
}: {
    db: ObjectDB;
    tree: Tree<ModelTreeNodeData>;
    path: string;
    fileTypes?: ModelTreeFilterObjects[];
    abortSignal: AbortSignal;
}): AsyncTreeChangeResult<ModelTreeNodeData> {
    const node = tree[path];
    if (!node || !node.hasMoreChildren || !node.children || node.loading) {
        return { tree };
    }

    return {
        tree: { ...tree, [path]: { ...node, loading: true } },
        rollback: (tree) => ({ ...tree, [path]: { ...node, loading: false } }),
        load: async () => {
            const { descendants, iterator } = await loadChildren({
                db,
                path,
                tree,
                fileTypes,
                abortSignal,
            });

            return (tree, _relevant) => {
                const node = tree[path];
                if (!node) {
                    return tree;
                }

                const newNode = appendDescendants({ tree, node, descendants, iterator });
                newNode.loading = false;
                return { ...tree, [path]: newNode };
            };
        },
    };
}

function appendDescendants({
    tree,
    node,
    descendants,
    iterator,
}: {
    tree: Tree<ModelTreeNodeData>;
    node: Node<ModelTreeNodeData>;
    descendants: HierarcicalObjectReference[];
    iterator?: AsyncIterableIterator<HierarcicalObjectReference>;
}) {
    for (const child of descendants) {
        let node = tree[child.path];
        if (!node) {
            node = makeNode(child);
            tree[child.path] = node;
        }
    }

    const newDescendants = node.children ? descendants.filter((d) => !node.children!.includes(d.path)) : descendants;

    return {
        ...node,
        children: [...(node.children ?? []), ...newDescendants.map((d) => d.path)],
        hasMoreChildren: Boolean(iterator),
        data: { ...node.data, childIterator: iterator },
    };
}

async function loadChildren({
    db,
    path,
    tree,
    fileTypes,
    abortSignal,
    ensureObjectFound,
}: {
    db: ObjectDB;
    tree: Tree<ModelTreeNodeData>;
    path: string;
    fileTypes?: ModelTreeFilterObjects[];
    abortSignal: AbortSignal;
    /**
     * By default we load first 500 children and the rest is loaded lazily.
     * But if we need to ensure some object (e.g. selected object) is loaded - we need to keep loading children
     * until we find that object.
     */
    ensureObjectFound?: HierarcicalObjectReference;
}) {
    const isFileOrInsideFile = getFilePathFromObjectPath(path);

    const pathFilter =
        fileTypes && !isFileOrInsideFile ? [...new Set(getDescendantsFilteredByParserTypeInfo(path, fileTypes))] : [];

    const node: Node<ModelTreeNodeData> | undefined = tree[path];
    const iterator =
        node?.data.childIterator ??
        db.search(
            {
                parentPath: path,
                descentDepth: 1,
                searchPattern: pathFilter.length ? [{ property: "path", value: pathFilter, exact: true }] : [],
            },
            abortSignal,
        );
    const descendants: HierarcicalObjectReference[] = [];
    let done = false;
    let objectFound = (ensureObjectFound && node?.children?.includes(ensureObjectFound.path)) ?? false;
    for (let i = 0; i < 500 || (ensureObjectFound && !objectFound); i++) {
        if (abortSignal.aborted) {
            break;
        }
        const next = await iterator.next();

        if (next.done) {
            done = true;
            break;
        }

        const child = next.value;
        descendants.push(child);
        if (ensureObjectFound && !objectFound && ensureObjectFound.path === child.path) {
            objectFound = true;
        }
    }

    return { descendants, iterator: done ? undefined : iterator };
}

export const emptyModelTree: Tree<ModelTreeNodeData> = {
    "": {
        expanded: false,
        name: "Root", // Root name is never displayed, just for debugging
        isLeaf: false,
        path: "",
        loading: true,
        data: (() => {
            const object = {
                id: -1,
                path: "",
                name: "Root",
                properties: [],
                save: async () => false,
                type: NodeType.Internal,
                loadMetaData: async () => object,
            };
            return { object };
        })(),
    },
};

export function filterTypeToStr(filter: ModelTreeFilter) {
    switch (filter.type) {
        case ModelTreeFilterType.Terrain:
            return "terrain";
        case ModelTreeFilterType.ParserType:
            return `parser:${filter.parserType}`;
        default:
            throw new Error("Unknown filter type");
    }
}
