import { ObjectData } from "@novorender/data-js-api";

import { flip, flipGLtoCadQuat, latLon2Tm } from "features/render/utils";

import { FlatImage, Image, PanoramaImage } from "./types";

export function isPanorama(image: Image): image is PanoramaImage {
    return "gltf" in image;
}

export function isFlat(image: Image): image is FlatImage {
    return "src" in image;
}

export function getImageFromObject({
    objectData,
    flip: shouldFlip,
    tmZone,
}: {
    objectData: ObjectData;
    flip: boolean;
    tmZone: string | undefined;
}): Image | null {
    const name = objectData.name;
    // NOTE(OLA): GUID property was not always uppercase
    const guid = objectData.properties.find((prop) => prop[0].toUpperCase() === "GUID")?.[1];
    const preview = objectData.properties.find((prop) => prop[0] === "Image/Preview")?.[1];
    if (!guid || !preview) {
        return null;
    }
    const gltf = objectData.properties.find((prop) => prop[0] === "Image/Gltf");
    const src = objectData.properties.find((prop) => prop[0] === "Image/Image");
    const pos = objectData.properties.find((prop) => prop[0] === "Image/Position");
    const rot = objectData.properties.find((prop) => prop[0] === "Image/Rotation");
    const lat = objectData.properties.find((prop) => prop[0] === "Image/Latitude");
    const lon = objectData.properties.find((prop) => prop[0] === "Image/Longitude");

    if (!gltf && !src) {
        return null;
    }

    if (pos && rot) {
        const base = {
            name,
            guid,
            preview,
            position: shouldFlip ? flip(JSON.parse(pos[1])) : JSON.parse(pos[1]),
        };

        if (gltf) {
            return {
                ...base,
                gltf: gltf[1],
                src: src ? src[1] : "",
                rotation: shouldFlip ? flipGLtoCadQuat(JSON.parse(rot[1])) : JSON.parse(rot[1]),
            };
        } else if (src) {
            return {
                ...base,
                src: src[1],
            };
        }
    } else if (lat && lon && tmZone) {
        const elevation = objectData.properties.find((prop) => prop[0] === "Image/Elevation");
        const position = latLon2Tm({ tmZone, coords: { latitude: Number(lat[1]), longitude: Number(lon[1]) } });
        if (elevation) {
            position[2] = Number(elevation[1]);
        }

        const base = {
            name,
            guid,
            preview,
            position,
        };

        if (gltf) {
            return {
                ...base,
                gltf: gltf[1],
                src: src ? src[1] : "",
            };
        } else if (src) {
            return {
                ...base,
                src: src[1],
            };
        }
    }

    return null;
}
