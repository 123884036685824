import { Download, FlightTakeoff, Folder, MoreVert, Visibility, VisibilityOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";
import { ObjectId } from "@novorender/api";
import { HierarcicalObjectReference, ObjectData } from "@novorender/data-js-api";
import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { useLazyGetFileDownloadLinkQuery } from "apis/dataV2/dataV2Api";
import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { ObjectTreeCheckbox } from "components/objectTree/objectTreeCheckbox";
import { ObjectTreeGroupExpander } from "components/objectTree/objectTreeGroupExpander";
import { ObjectTreeListItemButton } from "components/objectTree/objectTreeListItemButton";
import { ObjectTreeLoadMoreButton } from "components/objectTree/objectTreeLoadMoreButton";
import { ObjectTreeNodeExpander } from "components/objectTree/objectTreeNodeExpander";
import { ObjectTreeNodeLabel } from "components/objectTree/objectTreeNodeLabel";
import { ListNode, Node } from "components/objectTree/types";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { hiddenActions, useDispatchHidden, useHidden } from "contexts/hidden";
import { highlightActions, useDispatchHighlighted, useHighlighted } from "contexts/highlighted";
import { selectionBasketActions, useDispatchSelectionBasket } from "contexts/selectionBasket";
import { getImageFromObject } from "features/images";
import { renderActions } from "features/render";
import { isGlSpace } from "features/render/utils";
import useFlyTo from "hooks/useFlyTo";
import { selectTmZoneForCalc } from "slices/explorer";
import { AsyncState, AsyncStatus, NodeType } from "types/misc";
import { hasMouseSupport } from "utils/misc";
import { extractObjectIds, getTotalBoundingSphere } from "utils/objectData";
import { getDescendants, searchByParentPath } from "utils/search";

import { ModelTreeNodeData } from "../types";

type GroupState = "all" | "some" | "none";

const DOWNLOAD_PROPERTY = "Novorender/Download";

export function ModelTreeNode({
    item,
    setLoading,
    abortSignal,
    onToggleExpanded,
    onExpandGroup,
    onLoadMore,
    highlightPath,
}: {
    item: ListNode<ModelTreeNodeData>;
    setLoading: (value: boolean) => void;
    onToggleExpanded: (item: ListNode<ModelTreeNodeData>, node?: Node<ModelTreeNodeData>) => void;
    onExpandGroup: (node: ListNode<ModelTreeNodeData>) => void;
    onLoadMore: (node: ListNode<ModelTreeNodeData>) => void;
    abortSignal: AbortSignal;
    highlightPath: string | undefined;
}) {
    const {
        state: { db },
    } = useExplorerGlobals(true);
    const theme = useTheme();
    const highlightedSet = useHighlighted();
    const hiddenSet = useHidden();
    const dispatchHighlighted = useDispatchHighlighted();
    const dispatchHidden = useDispatchHidden();
    const dispatchSelectionBasket = useDispatchSelectionBasket();
    const dispatch = useAppDispatch();
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    const [metadata, setMetadata] = useState<AsyncState<ObjectData>>({ status: AsyncStatus.Initial });

    const selected: GroupState = useMemo(() => getGroupStateForIdSet(highlightedSet.ids, item), [item, highlightedSet]);

    const hidden = useMemo(() => getGroupStateForIdSet(hiddenSet.ids, item), [item, hiddenSet]);

    const highlight = useMemo(
        () =>
            highlightPath
                ? ((item.node.path === highlightPath ||
                      item.nodes?.some((n) => n.path === highlightPath) ||
                      item.nodeGroup?.some((n) => n.path === highlightPath)) ??
                  false)
                : false,
        [highlightPath, item],
    );

    const selectObject = async (
        nodes: HierarcicalObjectReference[],
        abortSignal: AbortSignal,
        setLoading: (value: boolean) => void,
    ) => {
        const isLeaf = nodes[0].type === NodeType.Leaf;
        if (isLeaf) {
            const ids = nodes.map((n) => n.id);
            dispatchHighlighted(highlightActions.add(ids));
            dispatchHidden(hiddenActions.remove(ids));
            if (nodes.length === 1) {
                dispatch(renderActions.setMainObject(nodes[0].id));
            }
            return;
        }

        setLoading(true);

        const node = nodes[0];
        try {
            try {
                await getDescendants({ db, parentNode: node, abortSignal }).then((ids) => {
                    dispatchHighlighted(highlightActions.add(ids));
                    dispatchHidden(hiddenActions.remove(ids));
                });
            } catch {
                await searchByParentPath({
                    db,
                    abortSignal,
                    parentPath: node.path,
                    callback: (refs) => {
                        const ids = extractObjectIds(refs);
                        dispatchHighlighted(highlightActions.add(ids));
                        dispatchHidden(hiddenActions.remove(ids));
                    },
                });
            }

            if (!abortSignal.aborted) {
                dispatchHighlighted(highlightActions.add([node.id]));
                dispatchHidden(hiddenActions.remove([node.id]));
                setLoading(false);
            }
        } catch {
            if (!abortSignal.aborted) {
                setLoading(false);
            }
        }
    };

    const unselectObject = async (
        nodes: HierarcicalObjectReference[],
        abortSignal: AbortSignal,
        setLoading: (value: boolean) => void,
    ) => {
        const isLeaf = nodes[0].type === NodeType.Leaf;
        const ids = nodes.map((n) => n.id);
        if (isLeaf) {
            return dispatchHighlighted(highlightActions.remove(ids));
        }

        dispatchHighlighted(highlightActions.remove(ids));

        setLoading(true);

        const node = nodes[0];
        try {
            try {
                await getDescendants({ db, parentNode: node, abortSignal }).then((ids) =>
                    dispatchHighlighted(highlightActions.remove(ids)),
                );
            } catch {
                await searchByParentPath({
                    db,
                    abortSignal,
                    parentPath: node.path,
                    callback: (refs) => dispatchHighlighted(highlightActions.remove(extractObjectIds(refs))),
                });
            }
        } catch {
            // nada
        } finally {
            if (!abortSignal.aborted) {
                setLoading(false);
            }
        }
    };

    const hide = async (nodes: HierarcicalObjectReference[]) => {
        if (nodes[0].type === NodeType.Leaf) {
            const ids = nodes.map((n) => n.id);
            dispatchHidden(hiddenActions.add(ids));
            dispatchHighlighted(highlightActions.remove(ids));
            dispatchSelectionBasket(selectionBasketActions.remove(ids));
            return;
        }

        setLoading(true);

        const node = nodes[0];
        try {
            try {
                await getDescendants({ db, parentNode: node, abortSignal }).then((ids) => {
                    dispatchHidden(hiddenActions.add(ids));
                    dispatchHighlighted(highlightActions.remove(ids));
                });
            } catch {
                await searchByParentPath({
                    db,
                    abortSignal,
                    parentPath: node.path,
                    callback: (refs) => {
                        const ids = extractObjectIds(refs);
                        dispatchHidden(hiddenActions.add(ids));
                        dispatchHighlighted(highlightActions.remove(ids));
                    },
                });
            }

            if (!abortSignal.aborted) {
                dispatchHidden(hiddenActions.add([node.id]));
                dispatchHighlighted(highlightActions.remove([node.id]));
                setLoading(false);
            }
        } catch {
            if (!abortSignal.aborted) {
                setLoading(false);
            }
        }
    };

    const show = async (nodes: HierarcicalObjectReference[]) => {
        const ids = nodes.map((n) => n.id);
        if (nodes[0].type === NodeType.Leaf) {
            return dispatchHidden(hiddenActions.remove(ids));
        }

        dispatchHidden(hiddenActions.remove(ids));

        setLoading(true);

        const node = nodes[0];
        try {
            try {
                await getDescendants({ db, parentNode: node, abortSignal }).then((ids) =>
                    dispatchHidden(hiddenActions.remove(ids)),
                );
            } catch {
                await searchByParentPath({
                    db,
                    abortSignal,
                    parentPath: node.path,
                    callback: (refs) => dispatchHidden(hiddenActions.remove(extractObjectIds(refs))),
                });
            }
        } catch {
            // nada
        } finally {
            if (!abortSignal.aborted) {
                setLoading(false);
            }
        }
    };

    const stopPropagation: React.MouseEventHandler = (e) => {
        e.stopPropagation();
    };

    const openMenu = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setMenuAnchor(e.currentTarget);

        if (!item.node.groupCollapsed) {
            setMetadata({ status: AsyncStatus.Loading });
            try {
                setMetadata({ status: AsyncStatus.Success, data: await item.node.data.object.loadMetaData() });
            } catch (ex) {
                console.warn(ex);
                setMetadata({ status: AsyncStatus.Initial });
            }
        }
    };

    const closeMenu = (e?: unknown) => {
        (e as React.MouseEvent<HTMLButtonElement>)?.stopPropagation();
        setMenuAnchor(null);
    };

    const handleClick = () => {
        if (!item.node.isLeaf) {
            onToggleExpanded(item);
            dispatch(renderActions.setMainObject(item.node.data.object.id));
        } else {
            handleSelection();
        }
    };

    const handleToggleVisibility = (e: React.ChangeEvent<HTMLInputElement>) => {
        const objects = item.nodeGroup?.map((n) => n.data.object) ?? [item.node.data.object];
        return e.target.checked ? hide(objects) : show(objects);
    };

    const handleSelection = () => {
        const objects = item.nodeGroup?.map((n) => n.data.object) ?? [item.node.data.object];
        if (selected === "all") {
            unselectObject(objects, abortSignal, setLoading);
        } else {
            selectObject(objects, abortSignal, setLoading);
        }
    };

    const currentNode = item.nodes?.at(-1) ?? item.node;

    const showExpander = !currentNode.isLeaf;

    if (item.isLoadMore) {
        return (
            <ObjectTreeLoadMoreButton
                item={item}
                onClick={() => {
                    onLoadMore(item);
                }}
            />
        );
    }

    return (
        <ObjectTreeListItemButton
            item={item}
            onClick={handleClick}
            selected={highlight}
            sx={
                menuAnchor
                    ? undefined
                    : {
                          "&:not(:hover) .show-on-hover": { display: "none" },
                      }
            }
        >
            {showExpander && <ObjectTreeNodeExpander item={item} onToggle={onToggleExpanded} />}
            <ObjectTreeNodeLabel
                flex="auto"
                item={item}
                sx={{ pl: showExpander ? undefined : theme.customSpacing.objectTreeExpanderPadding }}
                highlightPath={highlightPath}
                onNodeClick={(node) => {
                    dispatch(renderActions.setMainObject(node.data.object.id));
                }}
            />
            {hasMouseSupport() && (
                <IconButton onClick={openMenu} sx={{ p: 0.25 }} className="show-on-hover">
                    {metadata.status === AsyncStatus.Loading ? <CircularProgress size={24} /> : <MoreVert />}
                </IconButton>
            )}
            {item.nodeGroup && <ObjectTreeGroupExpander item={item} onClick={onExpandGroup} />}
            <ObjectTreeCheckbox
                name="toggle node highlight"
                aria-label="toggle node highlight"
                size="small"
                checked={selected === "all"}
                indeterminate={selected === "some"}
                onChange={handleSelection}
                onClick={stopPropagation}
            />
            <ObjectTreeCheckbox
                name="toggle node visibility"
                aria-label="Toggle node visibility"
                size="small"
                icon={<Visibility />}
                checkedIcon={<Visibility color="disabled" />}
                checked={hidden === "all"}
                indeterminate={hidden === "some"}
                indeterminateIcon={<VisibilityOutlined color="action" />}
                onChange={handleToggleVisibility}
                onClick={stopPropagation}
            />
            {!hasMouseSupport() && (
                <IconButton onClick={openMenu} sx={{ p: 0.25 }}>
                    {metadata.status === AsyncStatus.Loading ? <CircularProgress size={24} /> : <MoreVert />}
                </IconButton>
            )}

            <NodeMenu item={item} metadata={metadata} menuAnchor={menuAnchor} closeMenu={closeMenu} />
        </ObjectTreeListItemButton>
    );
}

function NodeMenu({
    item,
    metadata,
    menuAnchor,
    closeMenu,
}: {
    item: ListNode<ModelTreeNodeData>;
    metadata: AsyncState<ObjectData>;
    menuAnchor: HTMLElement | null;
    closeMenu: () => void;
}) {
    const {
        state: { scene },
    } = useExplorerGlobals(true);
    const tmZone = useAppSelector(selectTmZoneForCalc);
    const history = useHistory();
    const flyTo = useFlyTo();
    const [getFileDownloadLink] = useLazyGetFileDownloadLinkQuery();

    const getImage = useCallback(
        (objectData: ObjectData) => getImageFromObject({ objectData, flip: isGlSpace(scene.up), tmZone }),
        [scene.up, tmZone],
    );

    const handleFlyTo = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        closeMenu();

        const image = metadata.status === AsyncStatus.Success && getImage(metadata.data);

        if (image) {
            flyTo({ sphere: { center: image.position, radius: 20 } });
            return;
        }

        const sphere = item.nodeGroup
            ? getTotalBoundingSphere(item.nodeGroup.map((n) => n.data.object))
            : item.node.data.object.bounds?.sphere;
        if (sphere) {
            flyTo({ sphere });
        }
    };

    const handleSaveToGroups = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        closeMenu();
        history.push("/saveToGroups", { node: item.node });
    };

    const handleDownloadOrOpen = async () => {
        closeMenu();

        if (metadata.status !== AsyncStatus.Success) {
            return;
        }

        const relativeUrl = metadata.data.properties.find((p) => p[0] === DOWNLOAD_PROPERTY)![1];
        const downloadLink = await getFileDownloadLink({ relativeUrl }).unwrap();

        const link = document.createElement("a");
        link.href = downloadLink;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const canFlyTo = useMemo(() => {
        if (item.nodeGroup ? item.nodeGroup.some((n) => n.data.object.bounds) : item.node.data.object.bounds) {
            return true;
        }

        // Images don't have bounds, but they have (sometimes) coordinates stored under "Image" propety group
        if (metadata.status === AsyncStatus.Success && Boolean(getImage(metadata.data))) {
            return true;
        }

        return false;
    }, [item, metadata, getImage]);

    return (
        <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={closeMenu}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <MenuItem onClick={handleFlyTo} disabled={!canFlyTo}>
                <ListItemIcon>
                    <FlightTakeoff />
                </ListItemIcon>
                <ListItemText>{t("flyTo")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleSaveToGroups}>
                <ListItemIcon>
                    <Folder />
                </ListItemIcon>
                <ListItemText>{t("saveToGroups")}</ListItemText>
            </MenuItem>
            {metadata.status === AsyncStatus.Success &&
                metadata.data.properties.some((p) => p[0] === DOWNLOAD_PROPERTY) && (
                    <MenuItem onClick={handleDownloadOrOpen}>
                        <ListItemIcon>
                            <Download />
                        </ListItemIcon>
                        <ListItemText>{t("download")}</ListItemText>
                    </MenuItem>
                )}
        </Menu>
    );
}

function getGroupStateForIdSet(ids: Record<ObjectId, true | undefined>, item: ListNode<ModelTreeNodeData>) {
    if (item.nodeGroup) {
        let some = false;
        let all = true;
        for (const node of item.nodeGroup) {
            const inSet = ids[node.data.object.id] === true;
            some ||= inSet;
            all &&= inSet;
        }
        return all ? "all" : some ? "some" : "none";
    }

    return ids[item.node.data.object.id] ? "all" : "none";
}
