import { Alignment } from "@novorender/api";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { AsyncStatus } from "types/misc";

import { selectAlignmentIds as selectAlignmentIds, selectAlignments } from "../selectors";
import { alignmentActions } from "../slice";

export function useGetAlignments() {
    const {
        state: { view },
    } = useExplorerGlobals();

    const alignmentIds = useAppSelector(selectAlignmentIds);
    const alignments = useAppSelector(selectAlignments);

    const dispatch = useAppDispatch();

    useEffect(() => {
        loadAlignments();

        async function loadAlignments() {
            if (alignments.status !== AsyncStatus.Initial || alignmentIds.status !== AsyncStatus.Success || !view) {
                return;
            }

            dispatch(alignmentActions.setAlignments({ status: AsyncStatus.Loading }));

            try {
                const newAlignments: { [key: string]: Alignment } = {};
                for (const alignmentId of alignmentIds.data) {
                    const alignment = await view.measure?.road.getAlignment(alignmentId.id);
                    if (alignment) {
                        newAlignments[alignmentId.name] = alignment;
                    }
                }
                dispatch(alignmentActions.setAlignments({ status: AsyncStatus.Success, data: newAlignments }));
            } catch {
                dispatch(alignmentActions.setAlignments({ status: AsyncStatus.Error, msg: "An error occurred." }));
            }
        }
    }, [view, dispatch, alignments, alignmentIds]);

    return alignments;
}
