import { FeatureGroupKey, featureGroups, featuresConfig, WidgetKey } from "config/features";

export const groupSorting: FeatureGroupKey[] = [
    featureGroups.filesAndAttributes.key,
    featureGroups.collaboration.key,
    featureGroups.search.key,
    featureGroups.clipping.key,
    featureGroups.favorites.key,
    featureGroups.measure.key,
    featureGroups.validation.key,
    featureGroups.integrations.key,
    featureGroups.settings.key,
];

export const widgetSorting: WidgetKey[] = [
    featuresConfig.properties.key,
    featuresConfig.modelTree.key,
    featuresConfig.groups.key,
    featuresConfig.propertyTree.key,
    featuresConfig.selectionBasket.key,
    featuresConfig.bookmarks.key,
    featuresConfig.forms.key,
    featuresConfig.images.key,
    featuresConfig.myLocation.key,
    featuresConfig.search.key,
    featuresConfig.rangeSearch.key,
    featuresConfig.globalSearch.key,
    featuresConfig.clippingPlanes.key,
    featuresConfig.crossSection.key,
    featuresConfig.orthoCam.key,
    featuresConfig.followPath.key,
    featuresConfig.horizontalAlignment.key,
    featuresConfig.heightProfile.key,
    featuresConfig.measure.key,
    featuresConfig.pointLine.key,
    featuresConfig.outlineLaser.key,
    featuresConfig.manhole.key,
    featuresConfig.area.key,
    featuresConfig.deviations.key,
    featuresConfig.clash.key,
    featuresConfig.pointVisualization.key,
    featuresConfig.ditio.key,
    featuresConfig.jira.key,
    featuresConfig.bimcollab.key,
    featuresConfig.bimTrack.key,
    featuresConfig.omegaPims365.key,
    featuresConfig.xsiteManage.key,
    featuresConfig.omega365.key,
    featuresConfig.arcgis.key,
    featuresConfig.user.key,
    featuresConfig.offline.key,
    featuresConfig.advancedSettings.key,
];
