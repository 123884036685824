import { Box, css, ListItem, styled } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FixedSizeList, ListOnScrollProps } from "react-window";

import { Breadcrumbs } from "./breadcrumbs";
import { BreadcrumbNodeSpec, ListNode, Node, ObjectTreeNodeProps, Tree } from "./types";
import { defaultBreadcrumbNodeLabel, getParentPaths } from "./utils";

export function ObjectTree<T>({
    width,
    height,
    itemSize,
    tree,
    list,
    scrollTo,
    ListItem: ListItemFn,
    buildBreadcrumbs = defaultBreadcrumbBuilder,
}: {
    width: number;
    height: number;
    itemSize: number;
    tree: Tree<T>;
    list: ListNode<T>[];
    /**
     * ID of the item to scroll to. Scroll is executed only once `scrollTo` changes.
     */
    scrollTo?: string;
    ListItem: (props: ObjectTreeNodeProps<T>) => JSX.Element;
    buildBreadcrumbs?: (nodes: Node<T>[]) => BreadcrumbNodeSpec<T>[];
}) {
    const containerRef = useRef<FixedSizeList | null>(null);
    const [topVisibleItemIndex, setTopVisibleItemIndex] = useState(-1);
    const scrollFullfilledRef = useRef(false);

    useEffect(() => {
        scrollFullfilledRef.current = scrollTo ? false : true;
    }, [scrollTo]);

    // Scroll to scrollTo document
    useEffect(() => {
        if (!scrollTo || scrollFullfilledRef.current) {
            return;
        }
        let index = list.findIndex(
            (item) =>
                item.node.path === scrollTo ||
                item.nodes?.some((node) => node.path === scrollTo) ||
                item.nodeGroup?.some((node) => node.path === scrollTo),
        );
        if (index !== -1) {
            // Scroll so the selected item is second in the list to adjust for
            // possibly visible breadcrumbs.
            // Exception for the last item, because it's not visible with this adjustment.
            const breadcrumbAdjustment = index === list.length - 1 ? 0 : -1;

            const itemsFitIntoView = Math.round(height / itemSize);
            const maxIndex = Math.max(0, list.length - itemsFitIntoView);
            index = Math.min(maxIndex, Math.max(0, index + breadcrumbAdjustment));

            containerRef.current?.scrollTo(index * itemSize);
            scrollFullfilledRef.current = true;
        }
    }, [scrollTo, list, topVisibleItemIndex, height, itemSize]);

    const breadcrumbs = useMemo(() => {
        if (topVisibleItemIndex <= 1 || topVisibleItemIndex >= list.length) {
            return null;
        }

        const item = list[topVisibleItemIndex];
        const result: Node<T>[] = [];
        for (const path of getParentPaths(item.node.path)) {
            const node = tree[path];
            if (!node) {
                return null;
            }
            result.push(node);
        }

        return buildBreadcrumbs(result);
    }, [list, topVisibleItemIndex, tree, buildBreadcrumbs]);

    const handleBreadcrumbClick = useCallback(
        (node: Node<T>) => {
            if (node.path === "") {
                containerRef.current?.scrollToItem(0, "start");
            } else {
                const index = list.findIndex(
                    (e) => e.node.path === node.path || e.nodes?.some((n) => n.path === node.path),
                );
                if (index !== -1) {
                    const breadcrumbAdjustment = -1;
                    containerRef.current?.scrollToItem(index + breadcrumbAdjustment, "start");
                }
            }
        },
        [list],
    );

    const handleScroll = useCallback(
        (e: ListOnScrollProps) => {
            const breadcrumbAdjustment = 1;
            const topIndex = Math.max(0, Math.min(list.length - 1, Math.floor(e.scrollOffset / itemSize)));
            const lastIndex = Math.max(0, Math.min(list.length - 1, Math.ceil((e.scrollOffset + height) / itemSize)));
            if (list.length === 0 || lastIndex === 0) {
                setTopVisibleItemIndex(0);
                return;
            }

            setTopVisibleItemIndex(Math.min(list.length - 1, topIndex + breadcrumbAdjustment));
        },
        [height, list.length, itemSize],
    );

    return (
        <>
            <FixedSizeList
                height={height}
                width={width}
                itemSize={itemSize}
                itemCount={list.length}
                overscanCount={5}
                ref={containerRef}
                onScroll={handleScroll}
            >
                {({ index, style }) => {
                    const item = list[index];

                    return (
                        <ListItem
                            style={style}
                            key={item.isLoadMore ? `${item.node.path}__load_more` : item.node.path}
                            component="div"
                            disablePadding
                        >
                            <ListItemWrapper>
                                <ListItemFn item={item} />
                            </ListItemWrapper>
                        </ListItem>
                    );
                }}
            </FixedSizeList>

            <Breadcrumbs breadcrumbs={breadcrumbs} onClick={handleBreadcrumbClick} />
        </>
    );
}

const ListItemWrapper = styled(Box)(
    ({ theme }) => css`
        flex: auto;
        display: flex;
        alignitems: center;
        gap: ${theme.spacing(0.5)};
        my: ${theme.spacing(0.5)};
        height: 28px;
        width: 0;
    `,
);

function defaultBreadcrumbBuilder<T>(nodes: Node<T>[]) {
    return nodes.map((node) => {
        return { node, label: defaultBreadcrumbNodeLabel(node) };
    });
}
