import { Bookmark, ExplorerBookmarkState } from "@novorender/data-js-api";
import { ReadonlyVec3 } from "gl-matrix";

import { useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { useLazyHidden } from "contexts/hidden";
import { HighlightCollection, useLazyHighlightCollections } from "contexts/highlightCollections";
import { useLazyHighlighted } from "contexts/highlighted";
import { GroupStatus, isInternalGroup, useLazyObjectGroups } from "contexts/objectGroups";
import { useLazySelectionBasket } from "contexts/selectionBasket";
import { selectAlignment } from "features/alignment/selectors";
import { selectArcgisFeatureServers } from "features/arcgis/arcgisSlice";
import { selectAreas } from "features/area/areaSlice";
import {
    selectDeviationAlignmentDisplaySettings,
    selectDeviationLegendGroups,
    selectIsLegendFloating,
    selectSelectedProfileId,
    selectSelectedSubprofileIndex,
} from "features/deviations/selectors";
import {
    selectCurrentFormsList,
    selectFormItemId,
    selectSelectedFormId,
    selectSelectedFormObjectId,
} from "features/forms/slice";
import {
    selectManholeCollisionSettings,
    selectManholeCollisionTarget,
    selectManholeMeasureValues,
} from "features/manhole/manholeSlice";
import { selectMeasure } from "features/measure/measureSlice";
import {
    getMeasurePointsFromTracer,
    selectOutlineLaserPlane,
    selectOutlineLasers,
    TraceMeasurement,
} from "features/outlineLaser/outlineLaserSlice";
import { selectPointLines } from "features/pointLine/pointLineSlice";
import { selectPropertyTreeBookmarkState } from "features/propertyTree";
import {
    selectBackground,
    selectClippingPlanes,
    selectDefaultVisibility,
    selectGrid,
    selectMainObject,
    selectPoints,
    selectSelectionBasketMode,
    selectSubtrees,
    selectTerrain,
    selectViewMode,
} from "features/render/renderSlice";
import { SubtreeStatus } from "features/render/types";
import { AsyncStatus, ViewMode } from "types/misc";

export function useCreateBookmark() {
    const measurement = useAppSelector(selectMeasure);
    const defaultVisibility = useAppSelector(selectDefaultVisibility);
    const mainObject = useAppSelector(selectMainObject);
    const selectionBasketMode = useAppSelector(selectSelectionBasketMode);
    const alignment = useAppSelector(selectAlignment);
    const areas = useAppSelector(selectAreas);
    const pointLines = useAppSelector(selectPointLines);
    const subtrees = useAppSelector(selectSubtrees);
    const manhole = useAppSelector(selectManholeMeasureValues);
    const manholeCollisionTarget = useAppSelector(selectManholeCollisionTarget);
    const viewMode = useAppSelector(selectViewMode);
    const manholeCollisionSettings = useAppSelector(selectManholeCollisionSettings);
    const backgroundColor = useAppSelector(selectBackground).color;
    const clipping = useAppSelector(selectClippingPlanes);
    const terrain = useAppSelector(selectTerrain);
    const grid = useAppSelector(selectGrid);
    const points = useAppSelector(selectPoints);
    const deviations = points.deviation;
    const outlineLasers = useAppSelector(selectOutlineLasers);
    const laserPlane = useAppSelector(selectOutlineLaserPlane);
    const propertyTree = useAppSelector(selectPropertyTreeBookmarkState);
    const deviationProfileId = useAppSelector(selectSelectedProfileId);
    const deviationSubprofileIndex = useAppSelector(selectSelectedSubprofileIndex);
    const deviationLegendFloating = useAppSelector(selectIsLegendFloating);
    const deviationLegendGroups = useAppSelector(selectDeviationLegendGroups);
    const deviationAlignmentDisplaySettings = useAppSelector(selectDeviationAlignmentDisplaySettings);
    const arcgisFeatureServers = useAppSelector(selectArcgisFeatureServers);
    const currentFormsList = useAppSelector(selectCurrentFormsList);
    const selectedFormId = useAppSelector(selectSelectedFormId);
    const selectedFormObjectGuid = useAppSelector(selectSelectedFormId);
    const selectedFormObjectId = useAppSelector(selectSelectedFormObjectId);
    const formItemId = useAppSelector(selectFormItemId);

    const {
        state: { view },
    } = useExplorerGlobals(true);
    const groups = useLazyObjectGroups();
    const highlighted = useLazyHighlighted();
    const highlightCollections = useLazyHighlightCollections();
    const hidden = useLazyHidden();
    const selectionBasket = useLazySelectionBasket();

    const copyTraceMeasurement = (
        measurement: TraceMeasurement | undefined,
        laserPosition: ReadonlyVec3,
        startAr: ReadonlyVec3[],
        endAr: ReadonlyVec3[],
    ) => {
        if (measurement) {
            const pts = getMeasurePointsFromTracer(measurement, startAr, endAr);
            if (pts) {
                return { laserPosition: laserPosition, start: pts[0], end: pts[1] };
            }
            return undefined;
        }
        return undefined;
    };

    const create = (
        img?: string,
        explorerStateOverwrite: Partial<ExplorerBookmarkState> = { forms: undefined },
    ): Omit<Bookmark, "name" | "description" | "img"> & { img?: string; explorerState: ExplorerBookmarkState } => ({
        img,
        selectedOnly: false, // legacy
        explorerState: {
            viewMode,
            grid,
            clipping: {
                ...clipping,
                planes: clipping.planes.map(({ baseW: _baseW, ...plane }) => plane),
            },
            camera: view.renderState.camera,
            options: {
                addToSelectionBasket: false, // change on create() return value if needed
            },
            subtrees: {
                triangles: subtrees.triangles === SubtreeStatus.Shown,
                points: subtrees.points === SubtreeStatus.Shown,
                terrain: subtrees.terrain === SubtreeStatus.Shown,
                lines: subtrees.lines === SubtreeStatus.Shown,
                documents: subtrees.documents === SubtreeStatus.Shown,
            },
            background: {
                color: backgroundColor,
            },
            terrain: {
                asBackground: terrain.asBackground,
                elevationGradient: terrain.elevationGradient,
                canSelect: terrain.canSelect,
            },
            pointVisualization: {
                defaultPointVisualization: points.defaultPointVisualization,
                classificationColorGradient: points.classificationColorGradient,
            },
            deviations:
                viewMode === ViewMode.Deviations && deviationProfileId
                    ? {
                          index: deviations.index,
                          mixFactor: deviations.mixFactor,
                          profileId: deviationProfileId,
                          subprofileIndex: deviationSubprofileIndex,
                          isLegendFloating: deviationLegendFloating,
                          hiddenGroupIds: deviationLegendGroups
                              ?.filter((g) => g.status === GroupStatus.Hidden)
                              .map((g) => g.id),
                          alignmentDisplaySettings: deviationAlignmentDisplaySettings,
                      }
                    : undefined,
            groups: groups.current
                .filter((group) => !isInternalGroup(group))
                .filter((group) => group.status !== GroupStatus.None)
                .map(({ id, status }) => ({ id, status })),
            objects: {
                defaultVisibility,
                mainObject: {
                    id: mainObject,
                },
                hidden: { ids: hidden.current.idArr },
                highlighted: { ids: highlighted.current.idArr },
                highlightCollections: {
                    secondaryHighlight: {
                        ids: highlightCollections.current[HighlightCollection.SecondaryHighlight].idArr,
                    },
                },
                selectionBasket: { ids: selectionBasket.current.idArr, mode: selectionBasketMode },
            },
            measurements: {
                area: {
                    areas: areas.map((a) => {
                        return { points: a.points };
                    }),
                },
                pointLine: {
                    pointLines: pointLines.map((p) => p.points),
                },
                measure: {
                    entities: measurement.selectedEntities,
                    activeAxis: measurement.activeAxis,
                },
                manhole: {
                    id: manhole?.ObjectId,
                    collisionTarget: manholeCollisionTarget,
                    collisionSettings: manholeCollisionSettings,
                },
            },
            alignment:
                (viewMode === ViewMode.FollowPath || viewMode === ViewMode.Deviations) && alignment.alignment
                    ? {
                          alignment: alignment.alignment,
                          selectedStation: alignment.selectedStation,
                          verticalClipping: alignment.verticalClipping,
                          clipping: alignment.clipping,
                          followObject: alignment.followObject,
                      }
                    : undefined,
            outlineMeasure:
                outlineLasers.length > 0
                    ? {
                          laserPlane,
                          lasers: outlineLasers
                              .map((t) => {
                                  const measurementX = copyTraceMeasurement(
                                      t.measurementX,
                                      t.laserPosition,
                                      t.left,
                                      t.right,
                                  );
                                  const measurementY = copyTraceMeasurement(
                                      t.measurementY,
                                      t.laserPosition,
                                      t.down,
                                      t.up,
                                  );
                                  const measurementZ = copyTraceMeasurement(
                                      t.measurementZ,
                                      t.laserPosition,
                                      t.zDown,
                                      t.zUp,
                                  );
                                  if (
                                      measurementX === undefined &&
                                      measurementY === undefined &&
                                      measurementZ === undefined
                                  ) {
                                      return undefined;
                                  }
                                  return {
                                      laserPosition: t.laserPosition,
                                      measurementX,
                                      measurementY,
                                      measurementZ,
                                      laserPlanes: t.laserPlanes,
                                  };
                              })
                              .filter((f) => f !== undefined) as {
                              laserPosition: ReadonlyVec3;
                              measurementX?: { start: ReadonlyVec3; end: ReadonlyVec3 };
                              measurementY?: { start: ReadonlyVec3; end: ReadonlyVec3 };
                          }[],
                      }
                    : undefined,
            ...(propertyTree ? { propertyTree } : {}),
            arcgis:
                arcgisFeatureServers.status === AsyncStatus.Success
                    ? {
                          featureServers: arcgisFeatureServers.data.map((fs) => ({
                              id: fs.id,
                              layers: fs.layers.map((layer) => ({
                                  id: layer.id,
                                  checked: layer.checked,
                              })),
                          })),
                      }
                    : undefined,
            forms: {
                currentFormsList,
                selectedFormId,
                selectedFormObjectGuid,
                selectedFormObjectId,
                formItemId,
            },
            ...explorerStateOverwrite,
        },
    });

    return create;
}
