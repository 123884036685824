import { css, styled } from "@mui/material";

import { ListNode } from "./types";

export function ObjectTreeGroupExpander<T>({
    item,
    onClick,
}: {
    item: ListNode<T>;
    onClick: (item: ListNode<T>) => void;
}) {
    return item.nodeGroup ? (
        <GroupExpanderButton
            onClick={(e) => {
                e.stopPropagation();
                onClick(item);
            }}
        >
            {item.nodeGroup.length}
            {item.isLast && item.nodeGroup && item.parent?.hasMoreChildren ? "+" : null}
        </GroupExpanderButton>
    ) : null;
}

const GroupExpanderButton = styled("button")(
    ({ theme }) => css`
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        border-radius: 4px;
        transition: background-color 0.2s;
        padding: ${theme.spacing(0.5)};
        text-align: end;
        min-width: 2rem;

        &:hover {
            background-color: ${theme.palette.grey[200]};
        }
    `,
);
