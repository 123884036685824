import { Settings } from "@mui/icons-material";
import { Box, FormControlLabel, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MemoryRouter, Route, useHistory, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { Switch as IosSwitch } from "components";
import { featuresConfig } from "config/features";
import { ModelTree } from "features/modelTree/components/modelTree";
import { ModelTreeFilter, ModelTreeFilterType } from "features/modelTree/types";
import { emptyModelTree } from "features/modelTree/utils";
import { renderActions, selectSubtrees, selectTerrain, SubtreeStatus } from "features/render";

export function TerrainMenu({ onSelect: _onSelect }: { onSelect: () => void }) {
    const subtrees = useAppSelector(selectSubtrees);
    const showTerrain = subtrees.terrain !== SubtreeStatus.Unavailable;

    return showTerrain && <TerrainModelTree />;
}

const terrainParserTypes: ModelTreeFilter[] = [{ type: ModelTreeFilterType.Terrain }];

function TerrainModelTree() {
    const theme = useTheme();
    const [tree, setTree] = useState(emptyModelTree);

    return (
        <MemoryRouter initialEntries={["/tree"]}>
            <Box
                sx={{
                    maxHeight: `calc(100vh - ${theme.spacing(16)})`,
                    width: "400px",
                    maxWidth: `calc(100vw - ${theme.spacing(8)})`,
                    pb: 2,
                }}
            >
                <Header />
                <Route path="/settings">
                    <SettingsPanel />
                </Route>
                <Route>
                    {({ location }) => (
                        // Dont unmount model tree to avoid reloading
                        <Box display={location.pathname === "/tree" ? "block" : "none"}>
                            <ModelTree
                                tree={tree}
                                setTree={setTree}
                                fileLevel={false}
                                fileTypes={terrainParserTypes}
                                autoHeight
                                isInsideWidget={false}
                            />
                        </Box>
                    )}
                </Route>
            </Box>
        </MemoryRouter>
    );
}

function SettingsPanel() {
    const { t } = useTranslation();
    const terrain = useAppSelector(selectTerrain);
    const dispatch = useAppDispatch();

    return (
        <Box display="flex" flexDirection="column" gap={1} mt={1} ml={3}>
            <FormControlLabel
                id="render-terrain-as-background"
                control={
                    <IosSwitch
                        name="terrain-as-background"
                        checked={terrain.asBackground}
                        onChange={(_evt, checked) => dispatch(renderActions.setTerrain({ asBackground: checked }))}
                    />
                }
                label={
                    <Box ml={1} fontSize={16}>
                        {t("renderAsBackground")}
                    </Box>
                }
            />
            <FormControlLabel
                id="can-select-terrain"
                control={
                    <IosSwitch
                        name="can-select-terrain"
                        checked={terrain.canSelect}
                        onChange={(_evt, checked) => dispatch(renderActions.setTerrain({ canSelect: checked }))}
                    />
                }
                label={
                    <Box ml={1} fontSize={16}>
                        {t("selectable")}
                    </Box>
                }
            />
        </Box>
    );
}

function Header() {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const ModelTreeIcon = featuresConfig.modelTree.Icon;

    return (
        <Box display="grid" alignItems="center" gridTemplateColumns="auto min-content" pl={2} pr={0.25}>
            <Typography fontWeight="bold" fontSize="large">
                {t("terrain")}
            </Typography>
            <IconButton onClick={() => history.push(location.pathname === "/tree" ? "/settings" : "/tree")}>
                {location.pathname === "/tree" ? <Settings /> : <ModelTreeIcon />}
            </IconButton>
        </Box>
    );
}
