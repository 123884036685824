import { Alignment, FollowParametricObject, mergeRecursive } from "@novorender/api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReadonlyVec3 } from "gl-matrix";

import { resetView, selectBookmark } from "features/render";
import { AsyncState, AsyncStatus } from "types/misc";

import { AlignmentId, AlignmentView, ColoringType, HorizontalDisplaySettings, TracerType } from "./types";

const initialState = {
    alignment: undefined as { name: string; id: number } | undefined,
    alignmentIds: { status: AsyncStatus.Initial } as AsyncState<AlignmentId[]>,
    alignments: { status: AsyncStatus.Initial } as AsyncState<{ [key: string]: Alignment }>,
    showTracer: "off" as TracerType,
    verticalClipping: false,
    autoRecenter: false,
    step: 1 as number,
    hasTracer2dDrawProducts: false,
    view: AlignmentView.Pinhole,
    clipping: 0.01,
    showGrid: true,
    selectedStation: undefined as
        | {
              station: number;
              point: ReadonlyVec3;
              direction: ReadonlyVec3;
          }
        | undefined,
    horizontalDisplaySettings: {
        stations: {
            show: true,
            interval: 100,
            slope: false,
            elevation: false,
        },
        verticalSegment: {
            show: false,
            curvature: true,
            elevation: false,
            length: false,
            slope: false,
        },
        horizontalSegment: {
            show: true,
            curvature: true,
            elevation: false,
            length: false,
        },
        showPointsOfCurvature: true,
        showCurvatures: true,
        showAlignmentNames: false,
        coloringType: ColoringType.Curvature,
    } satisfies HorizontalDisplaySettings,
    followObject: undefined as undefined | FollowParametricObject,
    profileNumberFromBookmark: undefined as number | undefined,
    showFollowPathRedirectionSnackbar: false,
};

type State = typeof initialState;

export const alignmentSlice = createSlice({
    name: "alignment",
    initialState: initialState,
    reducers: {
        setAlignment: (state, action: PayloadAction<State["alignment"]>) => {
            state.alignment = action.payload;
        },
        setAlignmentIds: (state, action: PayloadAction<State["alignmentIds"]>) => {
            state.alignmentIds = action.payload;
        },
        setAlignments: (state, action: PayloadAction<State["alignments"]>) => {
            state.alignments = action.payload;
        },
        setSelectedStation: (state, action: PayloadAction<State["selectedStation"]>) => {
            state.selectedStation = action.payload;
        },
        updateDisplaySettings: (state, action: PayloadAction<Partial<HorizontalDisplaySettings>>) => {
            state.horizontalDisplaySettings = mergeRecursive(state.horizontalDisplaySettings, action.payload);
        },
        setShowTracer: (state, action: PayloadAction<State["showTracer"]>) => {
            state.showTracer = action.payload;
        },
        setVerticalClipping: (state, action: PayloadAction<State["verticalClipping"]>) => {
            state.verticalClipping = action.payload;
        },
        setAutoRecenter: (state, action: PayloadAction<State["autoRecenter"]>) => {
            state.autoRecenter = action.payload;
        },
        setFollowObject: (state, action: PayloadAction<State["followObject"]>) => {
            state.followObject = action.payload;
        },
        setStep: (state, action: PayloadAction<State["step"]>) => {
            state.step = action.payload;
        },
        setHasTracer2dDrawProducts: (state, action: PayloadAction<State["hasTracer2dDrawProducts"]>) => {
            state.hasTracer2dDrawProducts = action.payload;
        },
        setAlignmentView: (state, action: PayloadAction<State["view"]>) => {
            state.view = action.payload;
        },
        setClipping: (state, action: PayloadAction<State["clipping"]>) => {
            state.clipping = action.payload;
        },
        setShowGrid: (state, action: PayloadAction<State["showGrid"]>) => {
            state.showGrid = action.payload;
        },
        setProfileNumberFromBookmark: (state, action: PayloadAction<State["profileNumberFromBookmark"]>) => {
            state.profileNumberFromBookmark = action.payload;
        },
        setShowFollowPathRedirectionSnackbar: (
            state,
            action: PayloadAction<State["showFollowPathRedirectionSnackbar"]>,
        ) => {
            state.showFollowPathRedirectionSnackbar = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(selectBookmark, (state, action) => {
            const { followPath: fp, alignment } = action.payload;

            if (alignment) {
                state.alignment = alignment.alignment;
                state.selectedStation = alignment.selectedStation;
                state.verticalClipping = alignment.verticalClipping ?? false;
                state.clipping = alignment.clipping;
                state.followObject = alignment.followObject;
            } else if (fp) {
                if (typeof fp.selected.landXmlPathId === "number") {
                    state.alignment = {
                        id: fp.selected.landXmlPathId,
                        name: "", // name is populated in useLoadAlignmentFromLegacyBookmark
                    };
                }
                state.profileNumberFromBookmark = fp.profileNumber;
                // Selected station is populated in useLoadAlignmentFromLegacyBookmark
                state.selectedStation = undefined;
                state.verticalClipping = fp.verticalClipping ?? false;
                state.followObject = fp.followObject;
            }
        });
        builder.addCase(resetView, (state) => {
            state.alignment = undefined;
            state.selectedStation = undefined;
        });
    },
});

const { actions, reducer } = alignmentSlice;
export { actions as alignmentActions, reducer as alignmentReducer };
