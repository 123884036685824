import Intercom, { shutdown } from "@intercom/messenger-js-sdk";
import { useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useRef } from "react";

import { useGetIntercomConfigQuery } from "apis/dataV2/dataV2Api";
import { useAppSelector } from "app/redux-store-interactions";
import { selectSceneStatus } from "features/render";
import { selectUser } from "slices/authSlice";
import { selectNewDesign } from "slices/explorer";
import { AsyncStatus } from "types/misc";

export function useIntercom() {
    const user = useAppSelector(selectUser);
    const newDesign = useAppSelector(selectNewDesign);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const hidden = window.innerWidth < 1024;
    const sceneStatus = useAppSelector(selectSceneStatus);
    const mounted = useRef(false);

    const { data: serverConfig } = useGetIntercomConfigQuery(user && !hidden ? undefined : skipToken);

    useEffect(() => {
        if (
            user &&
            !hidden &&
            serverConfig &&
            (sceneStatus.status === AsyncStatus.Success || sceneStatus.status === AsyncStatus.Error)
        ) {
            const isError = sceneStatus.status === AsyncStatus.Error;

            Intercom({
                app_id: serverConfig.appId,
                region: "eu",
                user_id: user.user,
                user_hash: serverConfig.hash,
                name: user.name,
                email: user.user.match(/.+@.+\..+/) ? user.user : undefined,
                company: user.organization,
                alignment: "left",
                horizontal_padding: isError ? (isSmall ? 12 : 20) : newDesign ? 86 : 80,
                vertical_padding: isSmall ? 12 : 20,
                hide_default_launcher: newDesign && !isError,
            });
            mounted.current = true;
        }

        if (mounted.current && hidden) {
            shutdown();
        }
    }, [user, newDesign, hidden, isSmall, serverConfig, sceneStatus.status]);
}
