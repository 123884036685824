import { css } from "@emotion/react";
import { Box, Button, Slider, styled } from "@mui/material";
import { ReadonlyVec2 } from "gl-matrix";
import { forwardRef, memo, SyntheticEvent, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { areArraysEqual } from "features/arcgis/utils";
import {
    selectClosestToCenterFollowPathPoint,
    selectIsLegendFloating,
    selectRightmost2dDeviationCoordinate,
} from "features/deviations";
import { GroupsAndColorsHud } from "features/deviations/components/groupsAndColorsHud";
import { selectBackground } from "features/render";
import { selectViewMode } from "features/render";
import { useGetScreenQuadrant } from "hooks/useGetScreenQuadrant";
import { useRedirectWheelEvents } from "hooks/useRedirectWheelEvents";
import { ViewMode } from "types/misc";

import { useGoToStation } from "./hooks/useGoToStation";
import { selectAlignmentId, selectSelectedStation } from "./selectors";
import { selectClipping } from "./selectors";
import { selectAlignmentView } from "./selectors";
import { selectFollowObject } from "./selectors";
import { alignmentActions } from "./slice";
import { AlignmentView } from "./types";

export const AlignmentHtmlInteractions = forwardRef(function AlignmentHtmlInteractions(_props, ref) {
    const {
        state: { view },
    } = useExplorerGlobals();
    const viewMode = useAppSelector(selectViewMode);
    const rightmost2dDeviationCoordinate = useAppSelector(selectRightmost2dDeviationCoordinate);
    const lastRightmost2dDeviationCorrdinate = useRef(rightmost2dDeviationCoordinate);
    const alignmentView = useAppSelector(selectAlignmentView);
    const isCrossSectionView = alignmentView === AlignmentView.OrthoCrossSection;
    const isLegendFloating = useAppSelector(selectIsLegendFloating);
    const lastPt = useRef<ReadonlyVec2>();
    const bgColor = useAppSelector(selectBackground);
    const isBlackBg = bgColor && areArraysEqual(bgColor.color, [0, 0, 0, 1]);
    const legendOffset = useRef(160);
    const lastFov = useRef<number>();
    const centerLinePt = useAppSelector(selectClosestToCenterFollowPathPoint);
    const stationInfo = useAppSelector(selectSelectedStation);
    const alignmentId = useAppSelector(selectAlignmentId);
    const getQuadrant = useGetScreenQuadrant({ width: window.innerWidth, height: window.innerHeight, offset: 40 });

    const containerRef = useRef<HTMLDivElement | null>(null);

    const onWheel = useRedirectWheelEvents();

    useImperativeHandle(
        ref,
        () => ({
            update: () => {
                if (!view?.measure) {
                    return;
                }

                if (stationInfo && containerRef.current) {
                    const pt = view.measure.draw.toMarkerPoints([stationInfo.point])[0];
                    if (pt) {
                        containerRef.current.style.left = `${pt[0]}px`;
                        containerRef.current.style.top = `${pt[1]}px`;
                    }
                }
            },
        }),
        [view?.measure, stationInfo],
    );

    if (isCrossSectionView) {
        if (!view?.measure || !stationInfo) {
            return null;
        }

        const pt = view.measure.draw.toMarkerPoints([stationInfo.point])[0]! || lastPt.current;

        if (!pt) {
            return null;
        }

        lastPt.current = pt;

        const fov = view.renderState.camera.fov;
        if (
            Number.isFinite(rightmost2dDeviationCoordinate) &&
            rightmost2dDeviationCoordinate !== lastRightmost2dDeviationCorrdinate.current
        ) {
            legendOffset.current = Math.max(160, rightmost2dDeviationCoordinate! - pt[0] + 50);
            lastRightmost2dDeviationCorrdinate.current = rightmost2dDeviationCoordinate!;
            lastFov.current = fov;
        }

        const k = (lastFov.current ?? fov) / fov;
        let scaledLegendOffset = legendOffset.current * k;
        if (scaledLegendOffset > 500) {
            scaledLegendOffset = 140;
        }

        return (
            <div
                style={{
                    left: `${pt[0]}px`,
                    top: `${pt[1]}px`,
                    position: "absolute",
                    color: isBlackBg ? "white" : undefined,
                    fontWeight: 600,
                }}
                ref={containerRef}
                onWheel={onWheel}
            >
                <div
                    style={{
                        position: "absolute",
                        transform: `translate(-100px, -80px)`,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "200px",
                    }}
                >
                    <AlignmentPathControls />
                </div>

                {viewMode === ViewMode.Deviations && alignmentId && isLegendFloating && (
                    <div
                        style={{
                            position: "absolute",
                            transform: `translate(${scaledLegendOffset}px, 0px)`,
                            width: "400px",
                            bottom: 0,
                        }}
                    >
                        <GroupsAndColorsHud absPos />
                    </div>
                )}
            </div>
        );
    } else if (viewMode === ViewMode.Deviations && alignmentId && centerLinePt && isLegendFloating) {
        const quadrant = getQuadrant(centerLinePt);

        return (
            <LegendAlongCenterLine
                style={{
                    left: quadrant[0] === 0 ? `${centerLinePt[0]}px` : undefined,
                    top: quadrant[1] === 0 ? `${centerLinePt[1]}px` : undefined,
                    right: quadrant[0] === 1 ? `${window.innerWidth - centerLinePt[0]}px` : undefined,
                    bottom: quadrant[1] === 1 ? `${window.innerHeight - centerLinePt[1]}px` : undefined,
                }}
                onWheel={onWheel}
            >
                <GroupsAndColorsHud absPos={false} />
            </LegendAlongCenterLine>
        );
    }
});

const LegendAlongCenterLine = styled("div")(
    ({ theme }) => css`
        position: absolute;
        max-width: 400px;
        padding: 1rem;
        margin: 1rem;
        border-radius: ${theme.shape.borderRadius}px;
        background: ${theme.palette.background.paper};
    `,
);

const AlignmentPathControls = memo(function AlignmentPathControls() {
    const {
        state: { view },
    } = useExplorerGlobals();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const goToStation = useGoToStation();
    const stationInfo = useAppSelector(selectSelectedStation);
    const followObject = useAppSelector(selectFollowObject);
    const _clipping = useAppSelector(selectClipping);

    const [clipping, setClipping] = useState(_clipping);

    useEffect(() => setClipping(_clipping), [_clipping]);

    if (!view) {
        return null;
    }

    const handleClippingChange = (_event: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            return;
        }

        setClipping(newValue);
        if (view.renderState.camera.kind === "orthographic") {
            view.modifyRenderState({ camera: { far: newValue } });
        }
    };

    const handleClippingCommit = (_event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            return;
        }

        dispatch(alignmentActions.setClipping(newValue));
    };

    return (
        <Box position="absolute">
            <Box position="absolute" bottom="70px" sx={{ translate: "-50% 0" }}>
                <Button
                    variant="contained"
                    color="grey"
                    onClick={() => {
                        if (followObject) {
                            goToStation({ fpObj: followObject, station: stationInfo?.station, keepOffset: false });
                        }
                    }}
                >
                    {t("recenter")}
                </Button>
            </Box>

            <Box position="absolute" bottom="36px" whiteSpace="nowrap" textAlign="center" sx={{ translate: "-50% 0" }}>
                {`${t("clippingName")} ${clipping} m`}
            </Box>
            <Box position="absolute" bottom="0px" width="200px" sx={{ translate: "-50% 0" }}>
                <Slider
                    getAriaLabel={() => "Clipping far"}
                    value={clipping}
                    min={0.001}
                    max={1}
                    step={0.01}
                    onChange={handleClippingChange}
                    onChangeCommitted={handleClippingCommit}
                    valueLabelDisplay="off"
                />
            </Box>
        </Box>
    );
});
