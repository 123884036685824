import { featuresConfig } from "config/features";

export function ActiveIcon({
    Icon,
    active,
}: {
    Icon: typeof featuresConfig.area.Icon | typeof featuresConfig.crossSection.Icon;
    active: boolean;
}) {
    return <Icon color={active ? "primary" : undefined} />;
}
