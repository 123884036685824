import { vec3 } from "gl-matrix";
import { useMemo } from "react";

import { useAppSelector } from "app/redux-store-interactions";
import { selectIsTopDown } from "features/orthoCam";
import { CameraType, selectCameraType, selectProjectSettings } from "features/render";
import { latLon2Tm } from "features/render/utils";
import { AsyncStatus } from "types/misc";

import { useGetChecklistItemsQuery, useGetChecklistsQuery } from "../api";
import {
    FilterType,
    selectDitioAccessToken,
    selectDitioProjects,
    selectFilters,
    selectShowDitioChecklistInitialized,
    selectShowDitioChecklistMarkers,
} from "../slice";
import { Checklist, ChecklistStatus } from "../types";

const empty = [] as (Checklist & { position: vec3 })[];

export function useDitioChecklistMarkers() {
    const filters = useAppSelector(selectFilters);
    const projects = useAppSelector(selectDitioProjects);
    const { tmZone } = useAppSelector(selectProjectSettings);
    const showMarkers = useAppSelector(selectShowDitioChecklistMarkers);
    const cameraType = useAppSelector(selectCameraType);
    const token = useAppSelector(selectDitioAccessToken);
    const isInitialized = useAppSelector(selectShowDitioChecklistInitialized);

    const skipFetchingChecklists =
        !projects.length || token.status !== AsyncStatus.Success || !isInitialized || !showMarkers;
    const { data: approvedChecklistItemsMeta } = useGetChecklistItemsQuery(
        { projects, filters, status: ChecklistStatus.Approved },
        { skip: skipFetchingChecklists },
    );
    const { data: reportedChecklistItemsMeta } = useGetChecklistItemsQuery(
        { projects, filters, status: ChecklistStatus.Reported },
        { skip: skipFetchingChecklists },
    );
    const checklistItemsMeta = useMemo(() => {
        if (approvedChecklistItemsMeta && reportedChecklistItemsMeta) {
            return [...approvedChecklistItemsMeta, ...reportedChecklistItemsMeta];
        }
    }, [approvedChecklistItemsMeta, reportedChecklistItemsMeta]);
    const { data: checklists, isFetching: isFetchingChecklists } = useGetChecklistsQuery(
        { ids: (checklistItemsMeta ?? []).map((item) => item.Id) },
        { skip: !checklistItemsMeta?.length },
    );

    const isTopDown = useAppSelector(selectIsTopDown);

    const disabled = cameraType !== CameraType.Orthographic || !isTopDown || !showMarkers || !tmZone;

    const markers = useMemo(() => {
        if (!checklists?.length || disabled) {
            return empty;
        }

        return checklists
            .filter((checklist) => {
                if (!checklist.longitude || !checklist.latitude) {
                    return false;
                }
                const templateFilter = filters[FilterType.ChecklistTemplates];
                return (
                    templateFilter.length === 0 ||
                    templateFilter.some(
                        (template) =>
                            template.templateId === checklist.templateId && template.projectId === checklist.projectId,
                    )
                );
            })
            .slice(0, 100)
            .map((checklist) => ({
                ...checklist,
                position: latLon2Tm({
                    coords: { longitude: checklist.longitude, latitude: checklist.latitude },
                    tmZone,
                }),
            }));
    }, [checklists, disabled, tmZone, filters]);

    return isFetchingChecklists ? empty : markers;
}
