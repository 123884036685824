import { Alignment, DuoMeasurementValues } from "@novorender/api";
import { useCallback, useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { highlightActions, useDispatchHighlighted } from "contexts/highlighted";
import { useGetAlignments } from "features/alignment/hooks/useGetAlignments";
import { useGoToStation } from "features/alignment/hooks/useGoToStation";
import { selectAlignmentView } from "features/alignment/selectors";
import { alignmentActions } from "features/alignment/slice";
import { AlignmentView } from "features/alignment/types";
import { renderActions, selectViewMode } from "features/render";
import { AsyncStatus, ViewMode } from "types/misc";

import { selectSelectedCenterLineId, selectSelectedProfile } from "../selectors";

export function useFollowAlignment() {
    const {
        state: { view },
    } = useExplorerGlobals();
    const dispatch = useAppDispatch();
    const selectedProfile = useAppSelector(selectSelectedProfile);
    const selectedCenterLineId = useAppSelector(selectSelectedCenterLineId);
    const centerLine =
        selectedProfile && selectedCenterLineId
            ? selectedProfile.subprofiles.find((sp) => sp.centerLine?.brepId === selectedCenterLineId)?.centerLine
            : undefined;
    const followPathId = centerLine?.objectId;
    const dispatchHighlighted = useDispatchHighlighted();
    const installedFollowPathId = useRef<number>();
    const active = useAppSelector(selectViewMode) === ViewMode.Deviations;
    const alignments = useGetAlignments();

    const goToProfile = useGoToStation();
    const goToProfileRef = useRef(goToProfile);
    useEffect(() => {
        goToProfileRef.current = goToProfile;
    });

    const alignmentView = useAppSelector(selectAlignmentView);
    const alignmentViewRef = useRef(alignmentView);
    useEffect(() => {
        alignmentViewRef.current = alignmentView;
    });

    const restore = useCallback(() => {
        if (installedFollowPathId.current !== undefined) {
            // dispatch(renderActions.setViewMode(ViewMode.Default));
            dispatch(alignmentActions.setAlignment(undefined));
            dispatch(alignmentActions.setSelectedStation(undefined));
            dispatchHighlighted(highlightActions.remove([installedFollowPathId.current]));

            installedFollowPathId.current = undefined;
        }
    }, [dispatch, dispatchHighlighted]);

    useEffect(() => {
        return restore;
    }, [restore]);

    useEffect(() => {
        setFollowPath();

        async function setFollowPath() {
            if (!followPathId || !centerLine || !view || !active || alignments.status !== AsyncStatus.Success) {
                restore();
                return;
            }

            installedFollowPathId.current = undefined;
            let currentAlignment: Alignment | undefined;
            let alignmentName: string = "";
            for (const a of Object.entries(alignments.data)) {
                if (a[1].objectId == followPathId) {
                    alignmentName = a[0];
                    currentAlignment = a[1];
                    break;
                }
            }

            dispatch(renderActions.setViewMode(ViewMode.Deviations));
            dispatch(alignmentActions.setAlignment({ name: alignmentName, id: followPathId }));
            dispatch(renderActions.setMainObject(followPathId));
            dispatch(alignmentActions.setSelectedStation(undefined));
            dispatchHighlighted(highlightActions.setIds([followPathId]));
            if (view.measure) {
                const segment = await view.measure.core.pickCurveSegment(followPathId);
                if (segment) {
                    const measure = await view.measure.core.measure(segment, {
                        drawKind: "vertex",
                        ObjectId: -1,
                        parameter: view.renderState.camera.position,
                    });
                    if (measure && currentAlignment) {
                        const duoMeasure = measure as DuoMeasurementValues;
                        if (duoMeasure.measureInfoB && typeof duoMeasure.measureInfoB.parameter === "number") {
                            const pos = Math.max(
                                centerLine.parameterBounds[0],
                                Math.min(centerLine.parameterBounds[1], duoMeasure.measureInfoB.parameter),
                            );

                            if (alignmentViewRef.current !== AlignmentView.Pinhole) {
                                const fpObj = await view.measure?.followPath.followParametricObjects([followPathId], {
                                    cylinderMeasure: "center",
                                });

                                if (fpObj) {
                                    goToProfileRef.current({
                                        fpObj: fpObj,
                                        station: pos,
                                        newAlignmentView: alignmentViewRef.current,
                                        keepOffset: false,
                                        updateStationInfo: true,
                                    });
                                }
                            }
                        }
                    } else if (currentAlignment) {
                        const stationInfo = view.measure.road.getStationInfoAtPoint(
                            currentAlignment,
                            currentAlignment.points[0],
                        );
                        dispatch(alignmentActions.setSelectedStation(stationInfo));
                    }
                }
            }

            installedFollowPathId.current = followPathId;
        }
    }, [view, followPathId, dispatch, dispatchHighlighted, centerLine, restore, active, alignments]);
}
