import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AsyncState, AsyncStatus } from "types/misc";

import { emptyFileTypeFilters } from "./constants";
import { ModelTreeFilter } from "./types";

const initialState = {
    fileLevel: true,
    availableFileTypeFilters: { status: AsyncStatus.Initial } as AsyncState<ModelTreeFilter[]>,
    fileTypeFilters: emptyFileTypeFilters,
};

type State = typeof initialState;

export const modelTreeSlice = createSlice({
    name: "modelTree",
    initialState: initialState,
    reducers: {
        setFileLevel: (state, action: PayloadAction<State["fileLevel"]>) => {
            state.fileLevel = action.payload;
        },
        setAvailableFileTypeFilters: (state, action: PayloadAction<State["availableFileTypeFilters"]>) => {
            state.availableFileTypeFilters = action.payload;
        },
        setFileTypeFilters: (state, action: PayloadAction<State["fileTypeFilters"]>) => {
            state.fileTypeFilters = action.payload;
        },
    },
});

const { actions, reducer } = modelTreeSlice;
export { actions as modelTreeActions, reducer as modelTreeReducer };
