import { hslToVec, VecRGBA } from "utils/color";

export function getPropertyTreeColor(index: number) {
    return propertyTreeColors[index % propertyTreeColors.length];
}

const propertyTreeColors = createColors(12);

function createColors(count: number): VecRGBA[] {
    const increment = Math.floor(360 / count);
    return Array.from({ length: count }).map((_, i) => {
        return [...hslToVec(i * increment, 1, 0.5), 1];
    });
}
