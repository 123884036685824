import { Box, BoxProps, css, styled, Typography } from "@mui/material";
import { Fragment } from "react";

import { Tooltip } from "components/tooltip";

import { ListNode, Node } from "./types";

export function ObjectTreeNodeLabel<T>({
    item,
    onNodeClick,
    highlightPath,
    ...props
}: {
    item: ListNode<T>;
    onNodeClick: (node: Node<T>) => void;
    highlightPath: string | undefined;
} & BoxProps) {
    if (item.nodeGroup && item.node.groupKey) {
        return (
            <Tooltip title={item.node.groupKey}>
                <Container {...props}>
                    <Typography noWrap>{item.node.groupKey}</Typography>
                </Container>
            </Tooltip>
        );
    } else if (item.nodes) {
        const title = (
            <>
                <span style={{ textDecoration: item.node.path === highlightPath ? "underline" : undefined }}>
                    {item.node.name}
                </span>
                {item.nodes.map((node) => (
                    <Fragment key={node.path}>
                        /
                        <span style={{ textDecoration: node.path === highlightPath ? "underline" : undefined }}>
                            {node.name}
                        </span>
                    </Fragment>
                ))}
            </>
        );
        return (
            <Tooltip title={title}>
                <Container {...props}>
                    <Part
                        highlight={item.node.path === highlightPath}
                        onClick={(e) => {
                            e.stopPropagation();
                            onNodeClick(item.node);
                        }}
                    >
                        {item.node.name}
                    </Part>
                    {item.nodes.map((node) => (
                        <Fragment key={node.path}>
                            /
                            <Part
                                highlight={node.path === highlightPath}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onNodeClick(node);
                                }}
                            >
                                {node.name}
                            </Part>
                        </Fragment>
                    ))}
                </Container>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={item.node.name}>
                <Container {...props}>
                    <Typography noWrap>{item.node.name}</Typography>
                </Container>
            </Tooltip>
        );
    }
}

const Part = styled("span", { shouldForwardProp: (prop) => prop !== "highlight" })<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { highlight: boolean }
>(
    ({ highlight }) => css`
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: ${highlight ? "underline" : "unset"};
        white-space: nowrap;
    `,
);

const Container = styled(Box)(
    () => css`
        flex: auto;
        display: flex;
        overflow: hidden;
        width: 0;
    `,
);
