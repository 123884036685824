import { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { CameraType, selectCamera } from "features/render";
import { isTopDownRotation } from "features/render/utils";

import { selectAlignmentView } from "../selectors";
import { alignmentActions } from "../slice";
import { AlignmentView } from "../types";

/**
 * Sync alignment view with camera if somebody switches camera type from ortho shortcut (or some other way)
 */
export function useSyncAlignmentView() {
    const camera = useAppSelector(selectCamera);
    const rotationRef = useRef(camera.goTo?.rotation);
    rotationRef.current = camera.goTo?.rotation;
    const alignmentView = useAppSelector(selectAlignmentView);
    const alignmentViewRef = useRef(alignmentView);
    const dispatch = useAppDispatch();
    alignmentViewRef.current = alignmentView;

    useEffect(() => {
        if (camera.type === CameraType.Orthographic && alignmentViewRef.current === AlignmentView.Pinhole) {
            if (rotationRef.current && isTopDownRotation(rotationRef.current)) {
                dispatch(alignmentActions.setAlignmentView(AlignmentView.OrthoTopDown));
            } else {
                dispatch(alignmentActions.setAlignmentView(AlignmentView.OrthoCrossSection));
            }
        } else if (camera.type === CameraType.Pinhole && alignmentViewRef.current !== AlignmentView.Pinhole) {
            dispatch(alignmentActions.setAlignmentView(AlignmentView.Pinhole));
        }
    }, [dispatch, camera.type]);
}
