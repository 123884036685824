import { HierarcicalObjectReference } from "@novorender/data-js-api";

export type ModelTreeFilterObjects = {
    type: ModelTreeFilter;
    objects: HierarcicalObjectReference[];
};

export type ModelTreeNodeData = {
    object: HierarcicalObjectReference;
    childIterator?: AsyncIterableIterator<HierarcicalObjectReference>;
};

export enum ModelTreeFilterType {
    ParserType,
    Terrain,
}
export type ModelTreeFilter =
    | { type: ModelTreeFilterType.Terrain }
    | { type: ModelTreeFilterType.ParserType; parserType: string };
