import { Permission } from "apis/dataV2/permissions";

export const canvasContextMenuConfig = {
    hide: {
        key: "hide",
        permission: Permission.ContextHide,
    },
    hideLayer: {
        key: "hideLayer",
        permission: Permission.ContextHideLayer,
    },
    addFileToBasket: {
        key: "addFileToBasket",
        permission: Permission.ContextAddFileToBasket,
    },
    measure: {
        key: "measure",
        permission: Permission.ContextMeasure,
    },
    pointLine: {
        key: "pointLine",
        permission: Permission.ContextPointLine,
    },
    pickPoint: {
        key: "pickPoint",
        permission: Permission.ContextPickPoint,
    },
    area: {
        key: "area",
        permission: Permission.ContextArea,
    },
    laser: {
        key: "laser",
        permission: Permission.ContextLaser,
    },
    clip: {
        key: "clip",
        permission: Permission.ContextClip,
    },
} as const;

const config = canvasContextMenuConfig;
export const canvasContextMenuFeatures = Object.values(config);
export type CanvasContextMenuFeatureKey = keyof typeof config;
export const defaultCanvasContextMenuFeatures = [
    canvasContextMenuConfig.hide.key,
    canvasContextMenuConfig.hideLayer.key,
    canvasContextMenuConfig.addFileToBasket.key,
    canvasContextMenuConfig.measure.key,
    canvasContextMenuConfig.area.key,
    canvasContextMenuConfig.pickPoint.key,
    canvasContextMenuConfig.pointLine.key,
    canvasContextMenuConfig.laser.key,
    canvasContextMenuConfig.clip.key,
] as CanvasContextMenuFeatureKey[];
