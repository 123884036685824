import { Button, CircularProgress, ListItemButton, ListItemButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ListNode } from "./types";

export function ObjectTreeLoadMoreButton<T>({ item, sx = [], ...props }: { item: ListNode<T> } & ListItemButtonProps) {
    const { t } = useTranslation();

    return (
        <ListItemButton
            {...props}
            component="div"
            sx={[
                {
                    pl: 1 + item.level * 2,
                    pr: 1,
                    py: 0,
                    display: "flex",
                    justifyContent: "center",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Button
                disabled={item.node.loading}
                startIcon={item.node.loading ? <CircularProgress size={20} /> : undefined}
                size="small"
                sx={{ height: "100%", borderRadius: 0 }}
            >
                {item.node.loading ? t("loading") : `${t("more")}...`}
            </Button>
        </ListItemButton>
    );
}
