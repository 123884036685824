import { css, styled } from "@mui/material";
import { ForwardedRef, forwardRef, SVGProps, useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { featuresConfig } from "config/features";
import { translateInteraction } from "features/engine2D";
import { renderActions, selectClippingPlanes } from "features/render";
import { useOpenWidget } from "hooks/useOpenWidget";
import { AsyncStatus, ViewMode } from "types/misc";

import { useGetAlignments } from "./hooks/useGetAlignments";
import { useGoToStation } from "./hooks/useGoToStation";
import { selectStep } from "./selectors";
import { selectFollowObject } from "./selectors";
import { selectAlignmentId, selectSelectedStation } from "./selectors";
import { alignmentActions } from "./slice";

const markerStyles = () => css`
    cursor: pointer;
    pointer-events: auto;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));

    :hover {
        path {
            stroke: grey;
        }
        circle {
            stroke: grey;
            fill: white;
        }
    }
    path {
        stroke: black;
    }
    circle {
        stroke: black;
        fill: white;
    }
`;

const PlusMarker = styled(
    forwardRef((props: SVGProps<SVGGElement>, ref: ForwardedRef<SVGGElement>) => (
        <g {...props} ref={ref}>
            <rect width="24" height="24" fillOpacity={0} transform={"translate(88 88)"} />
            <circle r="12" transform={"translate(100 100)"} />
            <path d="M95,100 L105,100 M100,95 L100,105" strokeWidth={2}></path>
        </g>
    )),
    { shouldForwardProp: (prop) => prop !== "active" && prop !== "hovered" },
)(markerStyles);

const MinusMarker = styled(
    forwardRef((props: SVGProps<SVGGElement>, ref: ForwardedRef<SVGGElement>) => (
        <g {...props} ref={ref}>
            <rect width="24" height="24" fillOpacity={0} transform={"translate(88 88)"} />
            <circle r="12" transform={"translate(100 100)"} />
            <path d="M95,100 L105,100" strokeWidth={2}></path>
        </g>
    )),
    { shouldForwardProp: (prop) => prop !== "active" && prop !== "hovered" },
)(markerStyles);

const InfoMarker = styled(
    forwardRef((props: SVGProps<SVGGElement>, ref: ForwardedRef<SVGGElement>) => (
        <g {...props} ref={ref}>
            <rect width="24" height="24" fillOpacity={0} transform={"translate(88 88)"} />
            <circle r="12" transform={"translate(100 100)"} />
            <path
                d="M 12 5 A 1 1 0 0 0 12 7 A 1 1 0 0 0 12 5 M 11 10 H 13 V 17 H 11 Z"
                transform="translate(88 88)"
            ></path>
        </g>
    )),
    { shouldForwardProp: (prop) => prop !== "active" && prop !== "hovered" },
)(markerStyles);

const basicStyle = () => css`
    cursor: pointer;
    pointer-events: bounding-box;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
`;

const CloseMarker = styled(
    forwardRef((props: SVGProps<SVGGElement>, ref: ForwardedRef<SVGGElement>) => (
        <g {...props} ref={ref}>
            <rect width="24" height="24" fillOpacity={0} transform={"translate(88 88)"} />
            <circle r="10" fill="red" transform={"translate(100 100)"} />
            <path d="M96,96 L104,104 M104,96 L96,104" stroke="white" strokeWidth={2}></path>
        </g>
    )),
    { shouldForwardProp: (prop) => prop !== "active" && prop !== "hovered" },
)(basicStyle);
export function AlignmentInteractions() {
    const goToStation = useGoToStation();
    const fpObj = useAppSelector(selectFollowObject);
    const step = useAppSelector(selectStep);
    const dispatch = useAppDispatch();
    const openWidget = useOpenWidget();
    const clippingPlanes = useAppSelector(selectClippingPlanes);
    const hasClippingPlanes = clippingPlanes.planes.length > 0;
    const stationInfo = useAppSelector(selectSelectedStation);
    const alignments = useGetAlignments();
    const alignmentId = useAppSelector(selectAlignmentId);

    const plusMarker = useRef<SVGGElement | null>(null);
    const minusMarker = useRef<SVGGElement | null>(null);
    const infoMarker = useRef<SVGGElement | null>(null);
    const closeMarker = useRef<SVGGElement | null>(null);

    const stepFollow = (dir: number) => {
        if (fpObj && stationInfo) {
            const p = stationInfo.station;
            let next = p + dir * step;
            if (Number.isNaN(next)) {
                next = 1;
            }
            if (alignmentId && alignments.status === AsyncStatus.Success) {
                const currentAlignment = alignments.data[alignmentId.name];
                if (currentAlignment) {
                    if (next > currentAlignment.stations[currentAlignment.stations.length - 1]) {
                        next = currentAlignment.stations[currentAlignment.stations.length - 1];
                    } else if (next < currentAlignment.stations[0]) {
                        next = currentAlignment.stations[0];
                    }
                }
            }
            goToStation({ station: next, fpObj, updateStationInfo: true });
        }
    };

    useEffect(() => {
        if (!hasClippingPlanes || !stationInfo) {
            translateInteraction(plusMarker.current);
            translateInteraction(minusMarker.current);
            translateInteraction(infoMarker.current);
            translateInteraction(closeMarker.current);
        }
    }, [hasClippingPlanes, stationInfo]);

    return (
        <>
            <PlusMarker
                id={`followPlus`}
                name={`followPlus`}
                ref={plusMarker}
                onClick={() => {
                    stepFollow(1);
                }}
            />
            <MinusMarker
                id={`followMinus`}
                name={`followMinus`}
                ref={minusMarker}
                onClick={() => {
                    stepFollow(-1);
                }}
            />
            <InfoMarker
                id={`followInfo`}
                name={`followInfo`}
                ref={infoMarker}
                onClick={() => {
                    openWidget(featuresConfig.horizontalAlignment.key, { force: true });
                }}
            />
            <CloseMarker
                id="followClose"
                name="followClose"
                ref={closeMarker}
                onClick={() => {
                    dispatch(renderActions.setViewMode(ViewMode.Default));
                    dispatch(alignmentActions.setAlignment(undefined));
                    dispatch(alignmentActions.setSelectedStation(undefined));
                    dispatch(
                        renderActions.setClippingPlanes({
                            ...clippingPlanes,
                            planes: clippingPlanes.planes.slice(1),
                        }),
                    );
                }}
            />
        </>
    );
}
