import { Visibility } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { useMemo } from "react";

import { ColorStop } from "apis/dataV2/deviationTypes";
import { vecToRgb } from "utils/color";

export function GroupRainbowVisibilityIcon({ colorStops, ...props }: SvgIconProps & { colorStops: ColorStop[] }) {
    const stops = useMemo(
        () =>
            colorStops
                .map((cs, i) => {
                    const color = vecToRgb(cs.color);
                    const pos =
                        i === 0 ? 0 : i === colorStops.length - 1 ? 100 : Math.round((i / colorStops.length) * 100);
                    const rgba = `rgba(${color.r}, ${color.g}, ${color.b}, ${Math.max(color.a ?? 0, 0.2)})`;
                    return `<stop stop-color="${rgba}" offset="${pos}%"></stop>`;
                })
                .join(""),
        [colorStops],
    );

    return (
        <Visibility
            ref={(svg) => {
                if (!svg) {
                    return;
                }
                const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
                defs.innerHTML = `
                <linearGradient id="gradient">
                    ${stops}
                </linearGradient>`;
                svg.appendChild(defs);
                const path = svg.querySelector("path") as SVGElement;
                path.setAttribute("fill", "url(#gradient)");
            }}
            {...props}
        />
    );
}
