export type Project = {
    active: boolean;
    blockedForRegistration: boolean;
    companyId: string;
    description: null;
    estimatedHourQty: number;
    externalProjectNumber: null;
    finishedDate: string;
    finishedDatePlanned: string;
    id: string;
    mainContactEmail: null;
    mainForemanId: string;
    name: string;
    projectLeaderId: string;
    projectLocationLatitude: number;
    projectLocationLongitude: number;
    projectNumber: string;
    startDate: string;
    workBreakDinnerLength: number;
    workBreakDinnerStartTime: string;
    workBreakLunchLength: number;
    workBreakLunchStartTime: string;
};

export type Dumper = {
    id: string;
    kind: "dumper";
    companyId: string;
    companyName: string;
    projectId: string;
    projectName: string;
    taskName: string;
    dumperId: string;
    dumperDriverId: string;
    dumperDriverName: string;
    mobilePhoneNumber: string;
    lastSeen: string;
    appVersion: string;
    deviceNeedsUpdate: false;
    dumperHasLoad: boolean;
    massTypeName: string;
    quantity: number;
    speed: number;
    lastKnownLocation: {
        coordinates: [number, number];
        accuracy: number;
        isValidPoint: boolean;
        type: string;
    };
    dumperName: string;
    dumperAvatarSrc: string;
    distance: number;
    eventSource: string;
    scenePosition: Vec3;
};

export type Loader = {
    id: string;
    kind: "loader";
    companyId: string;
    companyName: string;
    loaderId: string;
    loaderName: string;
    loaderAvatarSrc: string;
    projectId: string;
    projectName: string;
    taskName: string;
    massTypeName: string;
    loaderDriverId: string;
    loaderDriverName: string;
    mobilePhoneNumber: string;
    lastKnownLocation: {
        type: string;
        coordinates: [number, number];
        accuracy: number;
        isValidPoint: false;
    };
    lastSeen: string;
    scenePosition: Vec3;
};

export type Machine = Dumper | Loader;

type GeoCoordinate = {
    Latitude: number;
    Longitude: number;
    Accuracy: number;
};

type FileReference = {
    CollectionRef: string;
    IdRef: string;
    SecondaryIdRefs: string[];
    FileName: string;
    FileNameOrig: string;
    FileType: string;
    FileSize: number;
    GeoCoordinate: GeoCoordinate;
    UserSecurityLevel: number;
    CreatedBy: string;
    CreatedDateTime: string;
    SyncState: number;
    IsImage: boolean;
    IsSvg: boolean;
    Id: string;
    CompanyId: string;
    RecVersion: number;
    CompanyIds: string[];
};

type FeedItemImage = {
    FileReferenceId: string;
    UrlSm: string;
    UrlM: string;
    UrlLg: string;
    Latitude: number;
    Longitude: number;
    Accuracy: number;
    IsImage: boolean;
    FileType: string;
    Name: string;
    CreatedDateTime: string;
};

type Like = {
    CollectionRef: string;
    IdRef: string;
    UserId: string;
    UserName: string;
    CompanyName: string;
    CreatedDateTime: string;
    AvatarImageId: string;
    AvatarSrcSm: string;
    AvatarSrcM: string | null;
    AvatarSrcLg: string | null;
    Id: string;
    CompanyId: string;
};

type Comment = {
    CollectionRef: string;
    IdRef: string;
    UserId: string;
    UserCompanyProfileId: string;
    UserName: string;
    CompanyName: string;
    Text: string;
    LikeCount: number;
    CreatedDateTime: string;
    ModifiedDateTime?: string;
    AvatarImageId: string;
    AvatarSrcSm: string;
    AvatarSrcM: string;
    AvatarSrcLg: string;
    Id: string;
    CompanyId: string;
};

export type FeedItem = {
    UserName: string;
    ProjNumber: string;
    ProjName: string;
    ProjCustomerName: string;
    TaskPath: string;
    TaskHierarchy: string[];
    TaskDescription: string;
    TaskName: string;
    NotificationName: string | null;
    ResourceDescription: string | null;
    ResourceName: string | null;
    EquipmentDescriptions: string[];
    FileReferences: FileReference[];
    FileCount: number;
    LikeCount: number;
    CommentCount: number;
    SkipCache: boolean;
    IdentityId: string;
    UserCompanyProfileId: string;
    UserIdLegacy: string;
    EmploymentId: string;
    CollectionRef: string;
    IdRef: string;
    ExternalReferenceId: string | null;
    ExternalResourceIds: string[];
    ExternalResourceUrls: string[];
    PostOriginType: PostOriginType;
    PostType: number;
    PostTicketStatus: number;
    AssignedTo: string | null;
    DeadlineDateTime: string;
    UserId: string;
    ProjId: string;
    TaskId: string;
    ResourceId: string | null;
    EquipmentIds: string[];
    Text: string;
    TaggedUserIds: string[];
    Published: boolean;
    Indexed: boolean;
    UserSecurityLevel: number;
    UserGroupId: string;
    GeoCoordinate: GeoCoordinate;
    CreatedDateTime: string;
    ModifiedDateTime: string;
    IndexedDateTime: string;
    AutoGenerated: boolean;
    SoftDeleted: boolean;
    ProjectCompanyId: string;
    Id: string;
    CompanyId: string;
    RecVersion: number;
    CompanyIds: string[];
    UserAvatarId: string;
    UserAvatarSrc: string;
    Images: FeedItemImage[];
    Likes: Like[];
    Comments: Comment[];
};

export enum SearchResultType {
    FeedPost = 0,
    Alert = 5,
    Checklist = 10,
}

export enum PostOriginType {
    Post,
    Alert,
    AlertV2,
}

export type ChecklistItemMeta = {
    Coordinate: GeoCoordinate;
    Id: string;
};

export type Checklist = {
    id: string;
    companyId: string;
    projectId: string;
    projectName: string;
    projectNumber: string;
    templateName: string;
    templateId: string;
    templateVersion: number;
    templateVersionDate: string;
    hasDeviation: boolean;
    hasImages: boolean;
    hasComment: boolean;
    status: number;
    createdByUserName: string;
    createdDateTime: string;
    submittedByUserName: string;
    submittedDateTime: string;
    modifiedByUserName: string;
    modifiedDateTime: string;
    approvedByUserName: string;
    latitude: number;
    longitude: number;
    accuracy: number;
    signatures: unknown[];
    activityName: string;
    activityNumber: string;
    dimensions: Dimension[];
    sections: Section[];
    pdfUrl: string;
    selfUrl: string;
};

type Dimension = {
    id: string;
    sortOrder: number;
    name: string;
    type: number;
    placeholder: string;
    value: string;
};

type Section = {
    id: string;
    header: string;
    repeatable: boolean;
    rowId: unknown | null;
    questions: Question[];
    comment: string | null;
    deviation: unknown | null;
    images: ChecklistImage[] | null;
};

type Question = {
    id: string;
    header: string;
    answerType: number;
    value: string;
    multiValue: unknown | null;
    comment: string | null;
    deviation: unknown | null;
    images: ChecklistImage[] | null;
};

type ChecklistImage = {
    id: string;
    url: string;
};

export type FeedItemMeta = {
    GeoCoordinate: GeoCoordinate;
    PostOriginType: PostOriginType;
    FileCount: number;
    Id: string;
};

export type GetDataResponse = {
    ElapsedMilliseconds: number;
    Projects: unknown[];
    Tasks: unknown[];
    Machines: unknown[];
    Checklists: ChecklistItemMeta[];
    FeedItems: FeedItemMeta[];
};

export type FeedItemPreview = {
    geoLocation: { lon: number; lat: number } | null;
    fileIds: string[];
    text: string;
    taskDescription: string;
    id: string;
    author: string;
    isAlert: boolean;
};

export enum ChecklistStatus {
    Pending,
    Submitted,
    Approved,
    Rejected,
    Reported,
}

export type Template = {
    templateId: string;
    templateName: string;
    parentId: string | null;
    projectId: string;
    attachToWholeProject: boolean;
    estimatedChecklistCount: number;
    attachStatus: number;
    isDisabled: boolean;
};
