import { rotationFromDirection } from "@novorender/api";
import { RenderSettings } from "@novorender/webgl-api";
import { quat, vec3, vec4 } from "gl-matrix";

import { ObjectVisibility } from "features/render";
import { MarkupHeader, Project, ProjectExtensions, Topic, Viewpoint } from "types/bcf";
import { VecRGB, VecRGBA, vecToHex } from "utils/color";
import { base64UrlEncodeImg, createCanvasSnapshot, uniqueArray } from "utils/misc";

type Point = {
    x: number;
    y: number;
    z: number;
};

type Direction = Point;

export type PerspectiveCamera = {
    camera_view_point: Point;
    camera_direction: Direction;
    camera_up_vector: Direction;
    field_of_view: number;
    aspect_ratio: number;
};

export type OrthogonalCamera = {
    camera_view_point: Point;
    camera_direction: Direction;
    camera_up_vector: Direction;
    view_to_world_scale: number;
    aspect_ratio: number;
};

export function translatePerspectiveCamera(perspectiveCamera: PerspectiveCamera): {
    rotation: quat;
    position: vec3;
    fov: number;
} {
    return {
        position: getCameraPosition(perspectiveCamera),
        rotation: getCameraRotation(perspectiveCamera),
        fov: perspectiveCamera.field_of_view,
    };
}

export function translateOrthogonalCamera(orthoCam: OrthogonalCamera): { rotation: quat; position: vec3; fov: number } {
    return {
        position: getCameraPosition(orthoCam),
        rotation: getCameraRotation(orthoCam),
        fov: orthoCam.view_to_world_scale,
    };
}

function getCameraRotation(camera: PerspectiveCamera | OrthogonalCamera): quat {
    // in BCF Z is Y
    const direction = vec3.negate(
        vec3.create(),
        vec3.fromValues(camera.camera_direction.x, camera.camera_direction.y, camera.camera_direction.z),
    );

    return rotationFromDirection(direction);
}

function getCameraPosition(camera: PerspectiveCamera | OrthogonalCamera): vec3 {
    return vec3.fromValues(camera.camera_view_point.x, camera.camera_view_point.y, camera.camera_view_point.z);
}

export function createPerspectiveCamera(
    camera: { position: vec3; rotation: quat; fov: number },
    aspectRatio = 1,
): PerspectiveCamera {
    const cameraDirection = vec3.transformQuat(vec3.create(), vec3.fromValues(0, 0, -1), camera.rotation);
    const cameraUp = vec3.transformQuat(vec3.create(), vec3.fromValues(0, 1, 0), camera.rotation);

    return {
        camera_view_point: {
            x: camera.position[0],
            y: camera.position[1],
            z: camera.position[2],
        },
        camera_direction: {
            x: cameraDirection[0],
            y: cameraDirection[1],
            z: cameraDirection[2],
        },
        camera_up_vector: {
            x: cameraUp[0],
            y: cameraUp[1],
            z: cameraUp[2],
        },
        field_of_view: camera.fov,
        aspect_ratio: aspectRatio,
    };
}

export function createOrthogonalCamera(
    camera: { position: vec3; rotation: quat; fov: number },
    aspectRatio = 1,
): OrthogonalCamera {
    const cameraDirection = vec3.transformQuat(vec3.create(), vec3.fromValues(0, 0, -1), camera.rotation);
    const cameraUp = vec3.transformQuat(vec3.create(), vec3.fromValues(0, 1, 0), camera.rotation);

    return {
        camera_view_point: {
            x: camera.position[0],
            y: camera.position[1],
            z: camera.position[1],
        },
        camera_direction: {
            x: cameraDirection[0],
            y: cameraDirection[1],
            z: cameraDirection[2],
        },
        camera_up_vector: {
            x: cameraUp[0],
            y: cameraUp[1],
            z: cameraUp[2],
        },
        view_to_world_scale: camera.fov,
        aspect_ratio: aspectRatio,
    };
}

export function translateBcfClippingPlanes(planes: Viewpoint["clipping_planes"]): Vec4[] {
    return planes.map(({ location, direction }) => {
        return vec4.fromValues(
            direction.x,
            direction.y,
            direction.z,
            vec3.dot(
                vec3.fromValues(direction.x, direction.y, direction.z),
                vec3.fromValues(location.x, location.y, location.z),
            ),
        ) as Vec4;
    });
}

export function createBcfClippingPlanes(
    planes: RenderSettings["clippingVolume"]["planes"],
): Viewpoint["clipping_planes"] {
    return planes.map((plane) => {
        const normal = vec3.fromValues(plane[0], plane[1], plane[2]);
        const pointOnPlane = vec3.scale(vec3.create(), normal, plane[3]);

        return {
            location: {
                x: pointOnPlane[0],
                y: pointOnPlane[1],
                z: pointOnPlane[2],
            },
            direction: {
                x: normal[0],
                y: normal[1],
                z: normal[2],
            },
        };
    });
}

export async function createBcfSnapshot(canvas: HTMLCanvasElement): Promise<Viewpoint["snapshot"] | undefined> {
    const snapshot = await createCanvasSnapshot(canvas, 1500, 1500);

    if (!snapshot) {
        return;
    }

    return { snapshot_type: "png", snapshot_data: snapshot.split(";base64,")[1] };
}

export async function createBcfViewpointComponents({
    selected,
    defaultVisibility,
    exceptions = [],
    coloring,
}: {
    selected: string[];
    coloring: { color: VecRGB | VecRGBA; guids: string[] }[];
    defaultVisibility: ObjectVisibility;
    exceptions?: string[];
}): Promise<Viewpoint["components"] | undefined> {
    return {
        selection: selected.map((guid) => ({ ifc_guid: guid })),
        coloring: coloring.map((item) => ({
            color: vecToHex(
                item.color.length === 3 ? item.color : ([item.color[3], ...item.color.slice(0, 4)] as VecRGBA),
            ),
            components: item.guids.map((guid) => ({ ifc_guid: guid })),
        })),
        visibility: {
            default_visibility: defaultVisibility === ObjectVisibility.Neutral,
            exceptions: uniqueArray(
                exceptions.concat(defaultVisibility === ObjectVisibility.Neutral ? [] : selected),
            ).map((guid) => ({ ifc_guid: guid })),
            view_setup_hints: {
                spaces_visible: false,
                space_boundaries_visible: false,
                openings_visible: false,
            },
        },
    };
}

export function handleImageResponse(res: Response): Promise<string> {
    return res.arrayBuffer().then((buffer) => `data:image/png;base64, ${base64UrlEncodeImg(buffer)}`);
}

export function viewpointToXml(doc: XMLDocument, viewpoint: Partial<Viewpoint>) {
    const VisualizationInfo = createXmlElement(doc, doc, "VisualizationInfo", { Guid: viewpoint.guid! });
    if (viewpoint.components) {
        const Components = createXmlElement(doc, VisualizationInfo, "Components");

        if (viewpoint.components.selection) {
            const Selection = createXmlElement(doc, Components, "Selection");
            viewpoint.components.selection.forEach((selection) => {
                if (selection.ifc_guid) {
                    createXmlElement(doc, Selection, "Component", { IfcGuid: selection.ifc_guid });
                }
            });
        }

        if (viewpoint.components.visibility) {
            const Visibility = createXmlElement(doc, Components, "Visibility", {
                DefaultVisibility: viewpoint.components.visibility.default_visibility ? "true" : "false",
            });

            if (viewpoint.components.visibility.exceptions) {
                const Exceptions = createXmlElement(doc, Visibility, "Exceptions");
                viewpoint.components.visibility.exceptions?.forEach((exception) => {
                    if (exception.ifc_guid) {
                        createXmlElement(doc, Exceptions, "Component", { IfcGuid: exception.ifc_guid });
                    }
                });
            }
        }
    }

    if (viewpoint.perspective_camera) {
        const PerspectiveCamera = createXmlElement(doc, VisualizationInfo, "PerspectiveCamera");

        const CameraViewPoint = createXmlElement(doc, PerspectiveCamera, "CameraViewPoint");
        createXmlElement(doc, CameraViewPoint, "X").textContent =
            viewpoint.perspective_camera.camera_view_point.x.toString();
        createXmlElement(doc, CameraViewPoint, "Y").textContent =
            viewpoint.perspective_camera.camera_view_point.y.toString();
        createXmlElement(doc, CameraViewPoint, "Z").textContent =
            viewpoint.perspective_camera.camera_view_point.z.toString();

        const CameraDirection = createXmlElement(doc, PerspectiveCamera, "CameraDirection");
        createXmlElement(doc, CameraDirection, "X").textContent =
            viewpoint.perspective_camera.camera_direction.x.toString();
        createXmlElement(doc, CameraDirection, "Y").textContent =
            viewpoint.perspective_camera.camera_direction.y.toString();
        createXmlElement(doc, CameraDirection, "Z").textContent =
            viewpoint.perspective_camera.camera_direction.z.toString();

        const CameraUpVector = createXmlElement(doc, PerspectiveCamera, "CameraUpVector");
        createXmlElement(doc, CameraUpVector, "X").textContent =
            viewpoint.perspective_camera.camera_up_vector.x.toString();
        createXmlElement(doc, CameraUpVector, "Y").textContent =
            viewpoint.perspective_camera.camera_up_vector.y.toString();
        createXmlElement(doc, CameraUpVector, "Z").textContent =
            viewpoint.perspective_camera.camera_up_vector.z.toString();

        createXmlElement(doc, PerspectiveCamera, "FieldOfView").textContent =
            viewpoint.perspective_camera.field_of_view.toString();
        createXmlElement(doc, PerspectiveCamera, "AspectRatio").textContent =
            viewpoint.perspective_camera.aspect_ratio.toString();
    }

    if (viewpoint.orthogonal_camera) {
        const OrthogonalCamera = createXmlElement(doc, VisualizationInfo, "OrthogonalCamera");

        const CameraViewPoint = createXmlElement(doc, OrthogonalCamera, "CameraViewPoint");
        createXmlElement(doc, CameraViewPoint, "X").textContent =
            viewpoint.orthogonal_camera.camera_view_point.x.toString();
        createXmlElement(doc, CameraViewPoint, "Y").textContent =
            viewpoint.orthogonal_camera.camera_view_point.y.toString();
        createXmlElement(doc, CameraViewPoint, "Z").textContent =
            viewpoint.orthogonal_camera.camera_view_point.z.toString();

        const CameraDirection = createXmlElement(doc, OrthogonalCamera, "CameraDirection");
        createXmlElement(doc, CameraDirection, "X").textContent =
            viewpoint.orthogonal_camera.camera_direction.x.toString();
        createXmlElement(doc, CameraDirection, "Y").textContent =
            viewpoint.orthogonal_camera.camera_direction.y.toString();
        createXmlElement(doc, CameraDirection, "Z").textContent =
            viewpoint.orthogonal_camera.camera_direction.z.toString();

        const CameraUpVector = createXmlElement(doc, OrthogonalCamera, "CameraUpVector");
        createXmlElement(doc, CameraUpVector, "X").textContent =
            viewpoint.orthogonal_camera.camera_up_vector.x.toString();
        createXmlElement(doc, CameraUpVector, "Y").textContent =
            viewpoint.orthogonal_camera.camera_up_vector.y.toString();
        createXmlElement(doc, CameraUpVector, "Z").textContent =
            viewpoint.orthogonal_camera.camera_up_vector.z.toString();

        createXmlElement(doc, OrthogonalCamera, "ViewToWorldScale").textContent =
            viewpoint.orthogonal_camera.view_to_world_scale.toString();
        createXmlElement(doc, OrthogonalCamera, "AspectRatio").textContent =
            viewpoint.orthogonal_camera.aspect_ratio.toString();
    }
}

export function projectExtensionsToXml(doc: XMLDocument, extensions: Partial<ProjectExtensions>) {
    const Extensions = createXmlElement(doc, doc, "Extensions");

    if (extensions.topic_type) {
        const TopicTypes = createXmlElement(doc, Extensions, "TopicTypes");
        extensions.topic_type.forEach((type) => {
            createXmlElement(doc, TopicTypes, "TopicType").textContent = type;
        });
    }

    if (extensions.topic_label) {
        const TopicLabels = createXmlElement(doc, Extensions, "TopicLabels");
        extensions.topic_label.forEach((type) => {
            createXmlElement(doc, TopicLabels, "TopicLabel").textContent = type;
        });
    }

    if (extensions.priority) {
        const Priorities = createXmlElement(doc, Extensions, "Priorities");
        extensions.priority.forEach((type) => {
            createXmlElement(doc, Priorities, "Priority").textContent = type;
        });
    }

    if (extensions.topic_status) {
        const TopicStatuses = createXmlElement(doc, Extensions, "TopicStatuses");
        extensions.topic_status.forEach((type) => {
            createXmlElement(doc, TopicStatuses, "TopicStatus").textContent = type;
        });
    }
}

export function markupToXml(
    doc: XMLDocument,
    header: Partial<MarkupHeader>,
    topic: Partial<Topic>,
    viewpoints: Partial<Viewpoint>[],
) {
    const Markup = createXmlElement(doc, doc, "Markup");

    if (header.files?.length) {
        const Header = createXmlElement(doc, Markup, "Header");
        const Files = createXmlElement(doc, Header, "Files");
        header.files?.forEach((file) => {
            const File = createXmlElement(doc, Files, "File");
            if (file.date) {
                createXmlElement(doc, File, "Date").textContent = file.date;
            }
            if (file.file_name) {
                createXmlElement(doc, File, "Filename").textContent = file.file_name;
            }
            if (file.reference) {
                createXmlElement(doc, File, "Reference").textContent = file.reference;
            }
        });
    }

    const Topic = createXmlElement(doc, Markup, "Topic", {
        Guid: topic.guid,
        TopicType: topic.topic_type,
        TopicStatus: topic.topic_status,
    });
    if (topic.title) {
        createXmlElement(doc, Topic, "Title").textContent = topic.title;
    }
    if (topic.creation_date) {
        createXmlElement(doc, Topic, "CreationDate").textContent = topic.creation_date;
    }
    if (topic.creation_author) {
        createXmlElement(doc, Topic, "CreationAuthor").textContent = topic.creation_author;
    }
    if (topic.modified_date) {
        createXmlElement(doc, Topic, "ModifiedDate").textContent = topic.modified_date;
    }
    if (topic.modified_author) {
        createXmlElement(doc, Topic, "ModifiedAuthor").textContent = topic.modified_author;
    }
    if (topic.priority) {
        createXmlElement(doc, Topic, "Priority").textContent = topic.priority;
    }
    if (topic.labels?.length) {
        const Labels = createXmlElement(doc, Topic, "Labels", {});
        topic.labels?.forEach((label) => {
            createXmlElement(doc, Labels, "Label").textContent = label;
        });
    }
    if (topic.description) {
        createXmlElement(doc, Topic, "Description").textContent = topic.description ?? "";
    }
    if (viewpoints.length) {
        const Viewpoints = createXmlElement(doc, Topic, "Viewpoints");
        viewpoints.forEach((viewpoint) => {
            const ViewPoint = createXmlElement(doc, Viewpoints, "ViewPoint", { Guid: viewpoint.guid });
            createXmlElement(doc, ViewPoint, "Viewpoint").textContent = `viewpoint_${viewpoint.guid}.bcfv`;
            if (viewpoint.snapshot) {
                createXmlElement(doc, ViewPoint, "Snapshot").textContent =
                    `snapshot_${viewpoint.guid}.${viewpoint.snapshot?.snapshot_type}`;
            }
        });
    }
}

export function projectToXml(doc: XMLDocument, project: Partial<Project>) {
    const ProjectInfo = createXmlElement(doc, doc, "ProjectInfo");
    const Project = createXmlElement(doc, ProjectInfo, "Project", { ProjectId: project.project_id });
    createXmlElement(doc, Project, "Name").textContent = project.name ?? "";
}

function createXmlElement(
    doc: XMLDocument,
    parent: XMLDocument | HTMLElement,
    name: string,
    attributes: Record<string, string | undefined> = {},
) {
    const element = doc.createElement(name);
    Object.entries(attributes)
        .filter(([_, value]) => value !== undefined)
        .forEach(([key, value]) => element.setAttribute(key, value!));
    parent.appendChild(element);
    return element;
}
