import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { HudPanel } from "components/hudPanel";
import { featuresConfig } from "config/features";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { useSceneId } from "hooks/useSceneId";
import { selectEnabledWidgets } from "slices/explorer";

import { offlineActions, selectOfflineScenes } from "./offlineSlice";

type SceneInfo = {
    openCount: number;
    suppressedPopup: boolean;
};

const openThreshold = 3;
let openProcessed = false;

export function IncrementalLoadingPopup() {
    const {
        state: { view, offlineWorkerState },
    } = useExplorerGlobals(true);
    const { t } = useTranslation();

    const sceneId = useSceneId();
    const [show, setShow] = useState(false);
    const dataRef = useRef<SceneInfo>({ openCount: 0, suppressedPopup: false });
    const parentSceneId = view.renderState.scene!.config.id;
    const currentParentScene = useAppSelector(selectOfflineScenes)[parentSceneId];
    const currentViewerScene = currentParentScene?.viewerScenes.find((scene) => scene.id === sceneId);
    const dispatch = useAppDispatch();
    const offlineWidgetAvailable = useAppSelector(selectEnabledWidgets).some(
        (widget) => widget.key === featuresConfig.offline.key,
    );

    useEffect(() => {
        if (openProcessed || !offlineWorkerState) {
            return;
        }

        const jsonStr = localStorage.getItem(getStorageKey(sceneId));
        try {
            if (jsonStr) {
                dataRef.current = JSON.parse(jsonStr);
            }
        } catch (e) {
            console.warn(e);
        }

        dataRef.current.openCount += 1;
        localStorage.setItem(getStorageKey(sceneId), JSON.stringify(dataRef.current));

        if (
            dataRef.current &&
            !dataRef.current.suppressedPopup &&
            dataRef.current.openCount >= openThreshold &&
            !currentViewerScene &&
            offlineWidgetAvailable
        ) {
            setShow(true);
        }

        openProcessed = true;
    }, [sceneId, currentViewerScene, offlineWorkerState, offlineWidgetAvailable]);

    if (!show) {
        return null;
    }

    const suppress = () => {
        dataRef.current.suppressedPopup = true;
        localStorage.setItem(getStorageKey(sceneId), JSON.stringify(dataRef.current));
    };

    const handleAccept = () => {
        suppress();
        dispatch(offlineActions.setAction({ action: "incrementalSync" }));
        setShow(false);
    };

    const handleSuppress = () => {
        suppress();
        setShow(false);
    };

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1060,
                }}
            ></Box>
            <Box
                sx={{
                    position: "absolute",
                    pointerEvents: "none",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    placeItems: "center",
                    zIndex: 1061,
                }}
            >
                <HudPanel
                    sx={{
                        pointerEvents: "auto",
                        p: 2,
                        maxWidth: "min(90vw, 600px)",
                        textAlign: "center",
                    }}
                >
                    <Typography fontWeight={600} mb={2}>
                        {t("incrementalPopupTitle")}
                    </Typography>
                    <Typography mb={1}>{t("incrementalPopupBody1")}</Typography>
                    <Typography>
                        {t("incrementalPopupBody2")}{" "}
                        <Button variant="text" color="error" onClick={handleSuppress}>
                            {t("clickHere")}
                        </Button>
                        {t("incrementalPopupBody3", { offline: t(featuresConfig.offline.nameKey) })}
                    </Typography>
                    <Box display="flex" justifyContent="center" mt={2} gap={2}>
                        <Button variant="outlined" color="primary" onClick={handleAccept}>
                            {t("close")}
                        </Button>
                    </Box>
                </HudPanel>
            </Box>
        </>
    );
}

function getStorageKey(sceneId: string) {
    return `incremental_loading_popup/${sceneId}`;
}
