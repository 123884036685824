import { Box, Button, ListItemText, Menu, MenuItem, Tooltip, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import { Fragment } from "react/jsx-runtime";

import { BreadcrumbNodeSpec, Node } from "./types";

export function Breadcrumbs<T>({
    breadcrumbs,
    onClick,
}: {
    breadcrumbs: BreadcrumbNodeSpec<T>[] | null;
    onClick: (node: Node<T>) => void;
}) {
    const theme = useTheme();
    if (!breadcrumbs || breadcrumbs.length <= 1) {
        return null;
    }

    return (
        <Box
            boxShadow={theme.customShadows.widgetHeader}
            sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                background: "white",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
            }}
        >
            {breadcrumbs.map((spec, i) => {
                let content: ReactNode;
                if (Array.isArray(spec)) {
                    content = <NodeGroupMenu nodes={spec} onClick={onClick} />;
                } else {
                    const { node, label } = spec;
                    content = (
                        <Tooltip title={label !== node.name ? node.name : undefined}>
                            <Button
                                color="grey"
                                onClick={() => onClick(node)}
                                size="small"
                                sx={{ minWidth: "unset", borderRadius: 0 }}
                            >
                                {label}
                            </Button>
                        </Tooltip>
                    );
                }

                return (
                    <Fragment key={i}>
                        {i > 0 && "/"}
                        {content}
                    </Fragment>
                );
            })}
        </Box>
    );
}

function NodeGroupMenu<T>({ nodes, onClick }: { nodes: Node<T>[]; onClick: (node: Node<T>) => void }) {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setMenuAnchor(e.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    return (
        <>
            <Button color="grey" size="small" sx={{ minWidth: "unset" }} onClick={openMenu}>
                ...
            </Button>

            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeMenu}>
                {nodes.map((node) => (
                    <MenuItem
                        key={node.path}
                        onClick={() => {
                            closeMenu();
                            onClick(node);
                        }}
                    >
                        <ListItemText>{node.name}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
