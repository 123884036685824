import { PickSampleExt } from "@novorender/api";
import { Intersection } from "@novorender/api/types/web_app/outline_inspect";
import { quat, ReadonlyQuat, ReadonlyVec3, vec3 } from "gl-matrix";

export function getLaserGizmoCoords(pick: PickSampleExt, lockedRotation: ReadonlyQuat | undefined) {
    const normal = pick.normal;

    const pos = pick.position;
    const z0 = pos;
    const z1 = vec3.add(vec3.create(), pos, normal);

    const minI = Math.abs(normal[2]) < Math.cos(0.08726646) ? 2 : 1; //Try to align y axis to up or if normal is already pointing up, then north. 5 degrees test
    const axisY = vec3.fromValues(0, 0, 0);
    axisY[minI] = 1;
    const xDir = vec3.cross(vec3.create(), axisY, normal);
    vec3.cross(xDir, axisY, normal);
    if (lockedRotation) {
        vec3.transformQuat(xDir, xDir, lockedRotation);
    }
    vec3.normalize(xDir, xDir);
    const x0 = vec3.add(vec3.create(), pos, xDir);
    const x1 = vec3.scaleAndAdd(vec3.create(), pos, xDir, -1);
    const yDir = vec3.cross(vec3.create(), normal, xDir);
    vec3.normalize(yDir, yDir);
    const y0 = vec3.add(vec3.create(), pos, yDir);
    const y1 = vec3.scaleAndAdd(vec3.create(), pos, yDir, -1);

    return { x0, x1, xDir, y0, y1, yDir, z0, z1 };
}

export function getLaserGizmoAlignmentRotation(
    intersection: Intersection,
    pos: ReadonlyVec3,
    xDir: ReadonlyVec3,
    yDir: ReadonlyVec3,
): quat | undefined {
    const l0 = intersection.left?.[0];
    const r0 = intersection.right?.[0];
    if (l0 && r0) {
        const alignedDir = vec3.sub(vec3.create(), r0, pos);
        vec3.normalize(alignedDir, alignedDir);
        return quat.rotationTo(quat.create(), xDir, alignedDir);
    }

    const u0 = intersection.up?.[0];
    const d0 = intersection.down?.[0];
    if (u0 && d0) {
        const alignedDir = vec3.sub(vec3.create(), d0, pos);
        vec3.normalize(alignedDir, alignedDir);
        return quat.rotationTo(quat.create(), yDir, alignedDir);
    }
}
