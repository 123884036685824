import { Box, type SpeedDialActionProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/redux-store-interactions";
import { SpeedDialAction } from "components";
import IconButtonExt from "components/iconButtonExt";
import { featuresConfig } from "config/features";
import { renderActions, selectSelectMultiple } from "features/render";

type Props = SpeedDialActionProps & { newDesign?: boolean };

export function MultipleSelection({ newDesign, ...props }: Props) {
    const { t } = useTranslation();
    const { nameKey, Icon } = featuresConfig["multipleSelection"];
    const active = useAppSelector(selectSelectMultiple);
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(renderActions.toggleSelectMultiple());
    };

    if (newDesign) {
        return (
            <Tooltip title={t(nameKey)} placement="left">
                <Box>
                    <IconButtonExt onClick={handleClick} active={active}>
                        <Icon />
                    </IconButtonExt>
                </Box>
            </Tooltip>
        );
    }

    return (
        <SpeedDialAction
            {...props}
            data-test="multiple-selection"
            active={active}
            onClick={handleClick}
            title={t(nameKey)}
            icon={<Icon />}
        />
    );
}
