import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";

import { ListNode } from "./types";

export function ObjectTreeNodeExpander<T>({
    item,
    onToggle,
}: {
    item: ListNode<T>;
    onToggle: (item: ListNode<T>) => void;
}) {
    const lastNode = item.nodes?.at(-1) ?? item.node;
    const showLoader = item.node.loading || item.nodes?.some((n) => n.loading);
    const showExpander = !lastNode.isLeaf;

    if (!showLoader && !showExpander) {
        return;
    }

    return (
        <IconButton
            color="default"
            size="small"
            onClick={(e) => {
                e.stopPropagation();
                onToggle(item);
            }}
        >
            {showLoader ? (
                <CircularProgress size={18} />
            ) : lastNode.expanded ? (
                <IndeterminateCheckBoxOutlined fontSize="inherit" />
            ) : (
                <AddBoxOutlined fontSize="inherit" />
            )}
        </IconButton>
    );
}
