import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { ReadonlyVec2, vec2, vec3 } from "gl-matrix";
import { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useAppSelector } from "app/redux-store-interactions";
import { Canvas2D } from "components";
import { useExplorerGlobals } from "contexts/explorerGlobals";
import { useLastPickSample } from "contexts/lastPickSample";
import { drawPart, getCameraState } from "features/engine2D";
import { CameraType, Picker, selectCamera, selectPicker } from "features/render";
import { selectNewDesign } from "slices/explorer";

import { selectLockedRotation, selectLockRotation, selectOutlineLaser3d } from "./outlineLaserSlice";
import { getLaserGizmoCoords } from "./utils";

export function OutlineLaserGizmoCanvas({
    renderFnRef,
}: {
    renderFnRef: MutableRefObject<((moved: boolean) => void) | undefined>;
}) {
    const theme = useTheme();
    const {
        state: { size, view },
    } = useExplorerGlobals();
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null | undefined>(null);
    const lastPickSample = useLastPickSample();
    const isOutlineLaserPicker = useAppSelector(selectPicker) === Picker.OutlineLaser;
    const laser3d = useAppSelector(selectOutlineLaser3d);
    const dirty = useRef(false);
    const cameraState = useAppSelector(selectCamera);
    const newDesign = useAppSelector(selectNewDesign);
    const lockRotation = useAppSelector(selectLockRotation);
    const lockedRotation = useAppSelector(selectLockedRotation);
    const [cursorPos, setCursorPos] = useState<ReadonlyVec2 | null>(null);
    const [iconPos, setIconPos] = useState<ReadonlyVec2 | null>(null);
    const lastIconPosQuad = useRef(0);

    const draw = useCallback(() => {
        if (!view?.measure || !ctx || !canvas) {
            return;
        }

        if (dirty.current) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        if (!lastPickSample) {
            setIconPos(null);
            return;
        }

        const normal = lastPickSample.normal;

        const pos = lastPickSample.position;
        const gizmoCoords = getLaserGizmoCoords(lastPickSample, lockedRotation?.rotation);
        let { x0, x1, y0, y1, z0, z1 } = gizmoCoords;
        let { xDir, yDir } = gizmoCoords;

        const outlineIntersection = view.screenSpaceLaser({
            laserPosition: lastPickSample.position,
            onlyOnOutlines: cameraState.type === CameraType.Orthographic,
            xDir,
            yDir,
            zDir: normal,
            autoAlign: lockedRotation === null,
        });
        let foundX = false;
        let foundY = false;
        let foundZ = false;

        let iconX0 = x0;
        if (outlineIntersection) {
            const l0 = outlineIntersection.left?.[0];
            const r0 = outlineIntersection.right?.[0];
            if (l0 && r0) {
                // if l0/r0 are swapped relative to x0/x1 - try to keep icon on one side
                iconX0 = vec3.dot(x0, l0) > vec3.dot(x1, l0) ? l0 : r0;

                x0 = l0;
                x1 = r0;
                foundX = true;
                xDir = vec3.sub(xDir, x1, x0);
                vec3.normalize(xDir, xDir);
            }

            const u0 = outlineIntersection.up?.[0];
            const d0 = outlineIntersection.down?.[0];
            if (u0 && d0) {
                y0 = u0;
                y1 = d0;
                foundY = true;
                yDir = vec3.sub(yDir, y1, y0);
                vec3.normalize(yDir, yDir);
            }

            if (foundX && !foundY) {
                yDir = vec3.cross(yDir, normal, xDir);
                y0 = vec3.add(y0, pos, yDir);
                y1 = vec3.scaleAndAdd(y1, pos, yDir, -1);
            } else if (!foundX && foundY) {
                xDir = vec3.cross(xDir, normal, vec3.negate(vec3.create(), yDir));
                x0 = vec3.add(x0, pos, xDir);
                x1 = vec3.scaleAndAdd(x1, pos, xDir, -1);
            }

            const zu0 = outlineIntersection.zUp?.[0];
            const zd0 = outlineIntersection.zDown?.[0];
            if (zu0 && zd0) {
                z0 = zu0;
                z1 = zd0;
                foundZ = true;
            }
        }

        const camera = getCameraState(view.renderState.camera);
        for (const [p1, p2, found, color] of [
            [x0, x1, foundX, "#8BC34A"],
            [y0, y1, foundY, "#03A9F4"],
            ...(laser3d ? [[z0, z1, foundZ, "#F44336"]] : []),
        ] as [vec3, vec3, boolean, string][]) {
            const drawProduct = view.measure?.draw.getDrawObjectFromPoints([p1, p2], {
                closed: false,
                angles: false,
                generateLengthLabels: true,
                decimals: 2,
            });
            for (const obj of drawProduct?.objects || []) {
                for (const part of obj.parts) {
                    dirty.current = true;
                    drawPart(
                        ctx,
                        camera,
                        part,
                        { lineColor: "rgba(255, 255, 255, 0.2)" },
                        4,
                        found ? { type: "center", unit: "m" } : undefined,
                    );
                    drawPart(
                        ctx,
                        camera,
                        part,
                        { lineColor: color },
                        2,
                        found ? { type: "center", unit: "m" } : undefined,
                    );
                }
            }
        }

        const iconYDir = vec3.cross(vec3.create(), normal, vec3.sub(vec3.create(), iconX0, pos));
        const iconY0 = vec3.add(vec3.create(), pos, iconYDir);
        const [pos2d, x2d, y2d] = view.convert.worldSpaceToScreenSpace([pos, iconX0, iconY0]);
        if (!pos2d || !x2d || !y2d) {
            return;
        }
        setCursorPos(pos2d);

        const x2dDir = vec2.sub(vec2.create(), x2d, pos2d);
        vec2.normalize(x2dDir, x2dDir);
        const y2dDir = vec2.sub(vec2.create(), y2d, pos2d);
        vec2.normalize(y2dDir, y2dDir);
        const y2dDirInv = vec2.negate(vec2.create(), y2dDir);
        const iconPosAngleDiff = vec2.angle(x2dDir, y2dDir) - vec2.angle(x2dDir, y2dDirInv);
        let iconPosQuad = 0;
        if (Math.abs(iconPosAngleDiff) < 10) {
            iconPosQuad = lastIconPosQuad.current;
        } else {
            iconPosQuad = iconPosAngleDiff > 0 ? 0 : 1;
        }
        lastIconPosQuad.current = iconPosQuad;
        const iconPos = vec2.lerp(vec2.create(), x2dDir, iconPosQuad === 0 ? y2dDir : y2dDirInv, 0.5);
        vec2.normalize(iconPos, iconPos);
        vec2.scale(iconPos, iconPos, 20);
        setIconPos(iconPos);
    }, [ctx, canvas, view, lastPickSample, laser3d, cameraState, lockedRotation]);

    useEffect(() => {
        draw();
    }, [draw]);

    useEffect(() => {
        renderFnRef.current = animate;
        return () => (renderFnRef.current = undefined);
        function animate(moved: boolean) {
            if (view && moved) {
                draw();
            }
        }
    }, [draw, renderFnRef, view]);

    let lockIcon: ReactNode | null = null;
    if (lockRotation && cursorPos && iconPos) {
        const translate = `${iconPos[0]}px ${iconPos[1]}px`;
        lockIcon = (
            <Box
                sx={{
                    translate: "-50% -50%",
                    pointerEvents: "none",
                    left: `${cursorPos[0]}px`,
                    top: `${cursorPos[1]}px`,
                    position: "absolute",
                    color: lockedRotation ? "#039BE5" : theme.palette.grey[800],
                }}
            >
                {lockedRotation ? <LockOutlined sx={{ translate }} /> : <LockOpenOutlined sx={{ translate }} />}
            </Box>
        );
    }

    return isOutlineLaserPicker && newDesign ? (
        <>
            <Canvas2D
                id="outline-laser-gizmo-canvas"
                data-include-snapshot
                ref={(el) => {
                    setCanvas(el);
                    setCtx(el?.getContext("2d") ?? null);
                }}
                width={size.width}
                height={size.height}
            />
            {lockIcon}
        </>
    ) : null;
}
