import { ListItemButton, ListItemButtonProps } from "@mui/material";

import { ListNode } from "./types";

export function ObjectTreeListItemButton<T>({
    item,
    sx = [],
    ...props
}: {
    item: ListNode<T>;
} & ListItemButtonProps) {
    return (
        <ListItemButton
            component="div"
            sx={[{ pl: 1 + item.level * 2, pr: 1, py: 0 }, ...(Array.isArray(sx) ? sx : [sx])]}
            {...props}
        ></ListItemButton>
    );
}
