import { segmentPlaneIntersection, View } from "@novorender/api";
import { glMatrix, ReadonlyVec3, ReadonlyVec4, vec3 } from "gl-matrix";

export function getOutlineLaser(
    pickPosition: ReadonlyVec3,
    view: View | undefined,
    mode: "clipping" | "outline",
    planes: ReadonlyVec4[],
    rotation?: number,
    perpendicularIdx?: number,
) {
    if (view) {
        const sp = view.measure?.draw.toMarkerPoints([pickPosition]);
        if (sp && sp.length > 0 && sp[0]) {
            const { renderState } = view;
            const { camera } = renderState;
            const laserPosition =
                mode == "clipping" && camera.kind == "pinhole"
                    ? segmentPlaneIntersection(
                        [camera.position, pickPosition],
                        renderState.clipping.planes[0].normalOffset,
                    )
                    : pickPosition;
            if (laserPosition) {
                const planeNormal = vec3.fromValues(planes[0][0], planes[0][1], planes[0][2]);
                const autoAlign =
                    Math.abs(vec3.dot(planeNormal, vec3.fromValues(0, 0, 1))) > 0.5 && rotation === undefined;
                const outlineValues = view.outlineLaser(
                    laserPosition,
                    mode,
                    0,
                    rotation,
                    autoAlign ? "closest" : undefined,
                );
                if (outlineValues) {
                    const perpendicularValues =
                        perpendicularIdx !== undefined && view.outlineLaser(laserPosition, mode, perpendicularIdx, 0);

                    const up = glMatrix.equals(Math.abs(vec3.dot(vec3.fromValues(0, 0, 1), planeNormal)), 1)
                        ? vec3.fromValues(0, 1, 0)
                        : vec3.fromValues(0, 0, 1);
                    const xDir = vec3.cross(vec3.create(), planeNormal, up);
                    vec3.normalize(xDir, xDir);
                    let swapAxis = false;
                    const shouldSwap = (dir: ReadonlyVec3, a: ReadonlyVec3, b: ReadonlyVec3) => {
                        const dir2 = vec3.sub(vec3.create(), a, b);
                        vec3.normalize(dir2, dir2);
                        if (Math.abs(vec3.dot(dir, dir2)) > 0.05) {
                            return true;
                        }
                        return false;
                    };
                    if (outlineValues.left.length > 0 && outlineValues.right.length > 0) {
                        swapAxis = shouldSwap(xDir, outlineValues.left[0], outlineValues.right[0]);
                    } else if (outlineValues.up.length > 0 && outlineValues.down.length > 0) {
                        const yDir = vec3.cross(vec3.create(), planeNormal, xDir);
                        vec3.normalize(yDir, yDir);
                        swapAxis = shouldSwap(yDir, outlineValues.up[0], outlineValues.down[0]);
                    }

                    if (swapAxis) {
                        let tmp = outlineValues.left;
                        outlineValues.left = outlineValues.down;
                        outlineValues.down = tmp;
                        tmp = outlineValues.right;
                        outlineValues.right = outlineValues.up;
                        outlineValues.up = tmp;
                    }

                    let perpendicularIntersections: ReadonlyVec3[] | undefined;

                    if (perpendicularValues && perpendicularValues.left.length > 0) {
                        const dir = vec3.sub(vec3.create(), perpendicularValues.left[0], laserPosition);
                        vec3.normalize(dir, dir);
                        const dot = vec3.dot(dir, planeNormal);
                        if (Math.abs(dot) > 0.9) {
                            perpendicularIntersections = dot < 0 ? perpendicularValues.right : perpendicularValues.left;
                        }
                    }
                    if (!perpendicularIntersections && perpendicularValues && perpendicularValues.up.length > 0) {
                        const dir = vec3.sub(vec3.create(), perpendicularValues.up[0], laserPosition);
                        vec3.normalize(dir, dir);
                        const dot = vec3.dot(dir, planeNormal);
                        if (Math.abs(dot) > 0.9) {
                            perpendicularIntersections = dot < 0 ? perpendicularValues.down : perpendicularValues.up;
                        }
                    }

                    return {
                        left: outlineValues.left,
                        right: outlineValues.right,
                        down: outlineValues.down,
                        up: outlineValues.up,
                        zUp: perpendicularIntersections ? perpendicularIntersections : [],
                        zDown: [pickPosition],
                        laserPosition: laserPosition,
                        measurementX:
                            outlineValues.left.length > 0 && outlineValues.right.length > 0
                                ? { startIdx: 0, endIdx: 0 }
                                : undefined,
                        measurementY:
                            outlineValues.down.length > 0 && outlineValues.up.length > 0
                                ? { startIdx: 0, endIdx: 0 }
                                : undefined,
                        measurementZ:
                            perpendicularValues && perpendicularIntersections && perpendicularIntersections.length > 0
                                ? { startIdx: 0, endIdx: 0 }
                                : undefined,
                        laserPlanes: perpendicularIdx ? planes : [],
                    };
                }
            }
        }
    }
    return undefined;
}

export type OutlineLaser = NonNullable<Awaited<ReturnType<typeof getOutlineLaser>>>;
