import { ReadonlyVec2, vec2 } from "gl-matrix";
import { useCallback, useRef } from "react";

/**
 * Assume screen is divided into 4 quadrants.
 * ```
 * +-----+-----+
 * | 0,0 | 1,0 |
 * +-----+-----+
 * | 0,1 | 1,1 |
 * +-----+-----+
 * ```
 * Get the quadrant the point is in, but take into account previous point position and add threshold to quadrant boundaries.
 * This helps avoiding flickering when point is near the boundary.
 */
export function useGetScreenQuadrant({ offset, width, height }: { offset: number; width: number; height: number }) {
    const lastQuadrant = useRef(vec2.create());

    return useCallback(
        (point: ReadonlyVec2) => {
            const next = vec2.clone(lastQuadrant.current);

            const hw = width / 2;
            if (next[0] === 0 && point[0] >= hw + offset) {
                next[0] = 1;
            } else if (next[0] === 1 && point[0] <= hw - offset) {
                next[0] = 0;
            }

            const hh = height / 2;
            if (next[1] === 0 && point[1] >= hh + offset) {
                next[1] = 1;
            } else if (next[1] === 1 && point[1] <= hh - offset) {
                next[1] = 0;
            }

            vec2.copy(lastQuadrant.current, next);

            return next;
        },
        [offset, width, height],
    );
}
