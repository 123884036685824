import { type RootState } from "app";

export const selectAlignment = (state: RootState) => state.alignment;
export const selectAlignmentIds = (state: RootState) => state.alignment.alignmentIds;
export const selectAlignments = (state: RootState) => state.alignment.alignments;
export const selectAlignmentId = (state: RootState) => state.alignment.alignment;
export const selectSelectedStation = (state: RootState) => state.alignment.selectedStation;
export const selectHorizontalDisplaySettings = (state: RootState) => state.alignment.horizontalDisplaySettings;
export const selectShowTracer = (state: RootState) => state.alignment.showTracer;
export const selectVerticalClipping = (state: RootState) => state.alignment.verticalClipping;
export const selectAutoRecenter = (state: RootState) => state.alignment.autoRecenter;
export const selectFollowObject = (state: RootState) => state.alignment.followObject;
export const selectStep = (state: RootState) => state.alignment.step;
export const selectHasTracer2dDrawProducts = (state: RootState) => state.alignment.hasTracer2dDrawProducts;
export const selectAlignmentView = (state: RootState) => state.alignment.view;
export const selectClipping = (state: RootState) => state.alignment.clipping;
export const selectShowGrid = (state: RootState) => state.alignment.showGrid;
export const selectProfileNumberFromBookmark = (state: RootState) => state.alignment.profileNumberFromBookmark;
export const selectShowFollowPathRedirectionSnackbar = (state: RootState) =>
    state.alignment.showFollowPathRedirectionSnackbar;
