import { ChevronRight, Close, Layers, MoreVert, SpaceDashboard } from "@mui/icons-material";
import { Box, css, Divider, Grow, IconButton, Popper, styled, Tooltip, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/redux-store-interactions";
import { HudPanel } from "components/hudPanel";
import IconButtonExt from "components/iconButtonExt";
import { CameraSpeed } from "features/cameraSpeed";
import { ClearSelection } from "features/clearSelection";
import { ClearView } from "features/clearView";
import { FlyToSelected } from "features/flyToSelected";
import { HideSelected } from "features/hideSelected";
import { Home } from "features/home";
import { MultipleSelection } from "features/multipleSelection";
import { OrthoShortcut } from "features/orthoShortcut";
import { selectSelectMultiple } from "features/render";
import { SelectionColor } from "features/selectionColor";
import { ShareLink } from "features/shareLink";
import { StepBack } from "features/stepBack";
import { StepForwards } from "features/stepForwards";
import { ViewOnlySelected } from "features/viewOnlySelected";
import { useToggle } from "hooks/useToggle";
import { selectIsVersionComparison, selectWidgetGroupPanelState } from "slices/explorer";

export function PrimaryMenuNew() {
    const theme = useTheme();
    const { t } = useTranslation();
    const [open, toggle] = useToggle(true);
    const panelRef = useRef<HTMLDivElement | null>(null);
    const widgetGroupPanelState = useAppSelector(selectWidgetGroupPanelState);
    const collapsedWidth = 56;
    const expandedWidthRef = useRef<number>();
    const [width, setWidth] = useState<number | undefined>();
    const isVersionComparison = useAppSelector(selectIsVersionComparison);

    const handleToggle = () => {
        setWidth(open ? collapsedWidth : expandedWidthRef.current);

        toggle();
    };

    useEffect(() => {
        if (open && panelRef.current && !expandedWidthRef.current) {
            expandedWidthRef.current = panelRef.current.querySelector("div")!.clientWidth;
            setWidth(expandedWidthRef.current);
        }
    }, [open]);

    const widgetGroupPanelWidth =
        widgetGroupPanelState.expanded && widgetGroupPanelState.open
            ? theme.customSpacing.widgetGroupPanelExpandedWidth
            : widgetGroupPanelState.open
              ? 12
              : 0;

    return (
        <Box
            sx={{
                zIndex: 1050,
                position: "absolute",
                pointerEvents: "none",
                display: "grid",
                placeItems: "end center",
                bottom: 0,
                left: `min(50%, 100% - ${theme.spacing(widgetGroupPanelWidth)} - ${
                    open ? (expandedWidthRef.current ?? 0) / 2 : collapsedWidth / 2
                }px)`,
                translate: "-50% 0",
                transition: "left 0.2s",
            }}
            ref={panelRef}
        >
            <HudPanel
                sx={{
                    display: "flex",
                    width: open ? width : collapsedWidth,
                    overflow: "hidden",
                    transition: "all 0.2s",
                    pointerEvents: "auto",
                }}
            >
                <Tooltip title={open ? t("collapse") : t("expand")} placement="top">
                    <Box>
                        <IconButton onClick={handleToggle}>{open ? <ChevronRight /> : <SpaceDashboard />}</IconButton>
                    </Box>
                </Tooltip>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
                <Home newDesign />
                <CameraSpeed newDesign />
                <StepBack newDesign />
                <StepForwards newDesign />
                <FlyToSelected newDesign />
                <OrthoShortcut newDesign />
                {!isVersionComparison && <ShareLink variant="primaryMenu" />}
                <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
                <ViewOnlySelected newDesign />
                <HideSelected newDesign />
                <ClearSelection newDesign />
                <ClearView newDesign />
                <MoreSelectionBtns />
            </HudPanel>
        </Box>
    );
}

function MoreSelectionBtns() {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

    const theme = useTheme();
    const isMultipleSelectionActive = useAppSelector(selectSelectMultiple);

    const openMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setMenuAnchor(e.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    return (
        <>
            <Box sx={{ position: "relative" }}>
                <IconButtonExt
                    onClick={(e) => (menuAnchor ? closeMenu() : openMenu(e))}
                    active={Boolean(menuAnchor)}
                    sx={{ transition: "rotate 0.1s", rotate: menuAnchor ? "90deg" : "0deg" }}
                >
                    {menuAnchor ? <Close /> : <MoreVert />}
                </IconButtonExt>
                <Box
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        display: "flex",
                        flexDirection: "column",
                        transition: "opacity 0.2s",
                        opacity: menuAnchor ? 0 : 1,
                    }}
                >
                    {isMultipleSelectionActive && (
                        <Layers
                            sx={{
                                color: theme.palette.primary.main,
                                fontSize: 14,
                            }}
                        />
                    )}
                </Box>
            </Box>

            <Popper anchorEl={menuAnchor} open={Boolean(menuAnchor)} placement="top" sx={{ pb: 1 }} transition>
                {({ TransitionProps }) => (
                    <>
                        <Grow {...TransitionProps} timeout={150}>
                            <MoreSectionBtnWrapper>
                                <MultipleSelection newDesign />
                            </MoreSectionBtnWrapper>
                        </Grow>
                        <Grow {...TransitionProps} timeout={200}>
                            <MoreSectionBtnWrapper>
                                <SelectionColor newDesign />
                            </MoreSectionBtnWrapper>
                        </Grow>
                    </>
                )}
            </Popper>
        </>
    );
}

const MoreSectionBtnWrapper = styled(HudPanel)(
    ({ theme }) => css`
        border-radius: 50%;
        padding: 2px;
        margin-bottom: ${theme.spacing(1)};
    `,
);
